<template>
    <div>
        <div>

            <v-navigation-drawer v-model="drawer" temporary width="400">
                <v-list>
                    <v-list-item value="navigazione" @click.prevent="drawer = !drawer">
                        <template v-slot:prepend>
                            <v-icon icon="bi-dash"></v-icon>
                        </template>

                        <v-list-item-title>
                            <b style="font-size: 18px;">Navigazione</b>
                        </v-list-item-title>

                        <template v-slot:append>
                            <v-icon icon="bi-x"></v-icon>
                        </template>
                    </v-list-item>
                </v-list>

                <v-divider></v-divider>

                <v-list density="compact" nav>

                    <div v-for="(elemento, i) in menu.elementi" :key="i">
                        <v-list-item :value="elemento.value">
                            <template v-slot:prepend>
                                <v-icon icon="bi-dash"></v-icon>
                            </template>

                            <v-list-item-title>
                                <b style="font-size: 16px;">{{ elemento.titolo }}</b>
                            </v-list-item-title>
                        </v-list-item>

                        <div v-for="(item, i) in elemento.items" :key="i">
                            <v-list-group v-if="item.tipologia === 'gruppo'" :value="item.value">
                                <template v-slot:activator="{ props }">
                                    <v-list-item v-bind="props" :prepend-icon="item.icona" :title="item.titolo">
                                    </v-list-item>
                                </template>

                                <v-list-item v-for="(itemLink, i) in item.items" :key="i" :value="itemLink.value"
                                    :title="itemLink.titolo" @click.prevent="handleNavigation(itemLink.route)"
                                    :prepend-icon="itemLink.icona">
                                </v-list-item>
                            </v-list-group>

                            <v-list-item v-if="item.tipologia === 'item'" :value="item.value"
                                :title="item.titolo" @click.prevent="handleNavigation(item.route)"
                                :prepend-icon="item.icona">
                            </v-list-item>
                        </div>

                    </div>

                    <v-divider></v-divider>

                    <v-list-item value="tech" title="Informazioni v4" prepend-icon="bi-git"></v-list-item>

                </v-list>

            </v-navigation-drawer>


            <v-slide-x-reverse-transition mode="out-in">
                <Teleport to="#target_toggle_navigation" v-if="isTargetAvailable">
                    <base-button v-if="inDashboard" icona="bi-grid-3x3-gap" @click.stop="drawer = !drawer"></base-button>
                </Teleport>
            </v-slide-x-reverse-transition>

        </div>


    </div>
</template>

<script>
import elementi from '../../../helpers/data/menu.helper';

export default {
    data() {
        return {
            drawer: false,
            isTargetAvailable: false,

            menu: {
                elementi: elementi
            }
        };
    },
    methods: {
        handleNavigation(route) {
            this.$router.push(route);

            /* if (gruppo) {
                gruppo.value = false;
            } */
        },
        checkTargetNavigation() {
            this.isTargetAvailable = !!document.getElementById('target_toggle_navigation');
        }
    },
    computed: {
        inDashboard() {
            return this.$route.meta.dashboard || false;
        },
    },
    mounted() {
        setTimeout(() => {
            this.checkTargetNavigation();
        }, 1000);

    }
}
</script>

<style scoped lang="scss">
@import '../../../assets/styles/variables';
/* X-Small devices (portrait phones, less than 576px)
No media query for `xs` since this is the default in Bootstrap */

.v-list-item__subtitle,
.v-list-item__title {
    text-overflow: initial !important;
    white-space: initial !important;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {}
</style>