const generaUsername = (nome, cognome) => {
    if(!nome || !cognome) {
        return null;
    }

    const arrayNomi = nome.split(" ");
    const arrayCognomi = cognome.split(" ");

    // Lo username verrà creato con il primo nome tutto in minuscolo e il primo cognome tutto in minuscolo, separati da un punto

    // Verifica Presenza due nomi
    const secondoNome = arrayNomi[1] ? '.' + arrayNomi[1].toLowerCase().replace("'", "") : null;

    const finalNome = arrayNomi[0].toLowerCase().replace("'", "") + (secondoNome ? secondoNome : '');
    const finalCognome = arrayCognomi[0].toLowerCase().replace("'", "");
    
    return finalNome + '.' + finalCognome;
};

export default generaUsername;