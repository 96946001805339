const ListaTipologiaNote = () => import('@/pages/dashboard_globale/manutenzione/nota/tipologia/ListaTipologiaNote.vue');
const CreaTipologiaNota = () => import('@/pages/dashboard_globale/manutenzione/nota/tipologia/CreaTipologiaNota.vue');
const ModificaTipologiaNota = () => import('@/pages/dashboard_globale/manutenzione/nota/tipologia/ModificaTipologiaNota.vue');

const routes = [
    {
        name: 'tipologia.nota.index',
        path: '/dashboard/tipologia/note',
        component: ListaTipologiaNote,
        meta: {
            titolo: 'Tipologia Nota',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'tipologia.nota.create',
        path: '/dashboard/tipologia/nota/create',
        component: CreaTipologiaNota,
        meta: {
            titolo: 'Crea Tipologia Nota',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'tipologia.nota.edit',
        path: '/dashboard/tipologia/nota/:id/edit',
        component: ModificaTipologiaNota,
        meta: {
            titolo: 'Modifica Tipologia Nota',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;