const ListaPriorityTicket = () => import('@/pages/dashboard_globale/area/helpdesk/priority/ListaPriority.vue');
const CreaPriorityTicket = () => import('@/pages/dashboard_globale/area/helpdesk/priority/CreaPriority.vue');
const ModificaPriorityTicket = () => import('@/pages/dashboard_globale/area/helpdesk/priority/ModificaPriority.vue');

const routes = [
    {
        name: 'priority.ticket.index',
        path: '/dashboard/priority/ticket',
        component: ListaPriorityTicket,
        meta: {
            titolo: 'Priority Ticket',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'priority.ticket.create',
        path: '/dashboard/priority/ticket/create',
        component: CreaPriorityTicket,
        meta: {
            titolo: 'Crea Priority Ticket',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'priority.ticket.edit',
        path: '/dashboard/priority/ticket/:priorityId/edit',
        component: ModificaPriorityTicket,
        meta: {
            titolo: 'Modifica Priority Ticket',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;