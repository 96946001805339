const ListaPromozione = () => import('@/pages/dashboard_globale/area/amministrazione/promozioneProdotto/ListaPromozione.vue');
const CreaPromozione = () => import('@/pages/dashboard_globale/area/amministrazione/promozioneProdotto/CreaPromozione.vue');
const ModificaPromozione = () => import('@/pages/dashboard_globale/area/amministrazione/promozioneProdotto/ModificaPromozione.vue');

const routes = [
    {
        name: 'promozione.index',
        path: '/dashboard/promozione',
        component: ListaPromozione,
        meta: {
            titolo: 'Promozioni',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'promozione.create',
        path: '/dashboard/promozione/create',
        component: CreaPromozione,
        meta: {
            titolo: 'Crea Promozione',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'promozione.edit',
        path: '/dashboard/promozione/:promozioneId/edit',
        component: ModificaPromozione,
        meta: {
            titolo: 'Modifica Promozione',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;