<template>
    <div>
        <v-progress-linear :active="loading" :indeterminate="loading" color="#004699" class="custom_loading"></v-progress-linear>

        <the-navbar></the-navbar>

        <SignedIn>
            <front-navigation v-if="!inDashboard"></front-navigation>
            <dash-navigation v-else></dash-navigation>
        </SignedIn>

    </div>
</template>

<script>

import FrontNavigation from "@/components/UI/layout/FrontNavigation.vue";
import DashNavigation from "@/components/UI/layout/DashNavigation.vue";

import { SignedIn } from 'vue-clerk';

export default {
    inject: ['moshaToast'],
    components: {
        FrontNavigation,
        DashNavigation,
        SignedIn
    },
    data() {
        return {
            loading: false
        };
    },
    methods: {
        daSviluppare() {
            this.moshaToast(
                {
                    title: 'Handle non Implementato!',
                    description: `Sviluppo EOS in Corso.`
                },
                {
                    showIcon: true,
                    hideProgressBar: false,
                    timeout: 6000,
                    type: 'info',
                    toastBackgroundColor: '#292b2c',
                    transition: 'bounce',
                    showCloseButton: true,
                    swipeClose: true,
                    position: 'bottom-right'
                }
            );
        },
    },
    computed: {
        inDashboard() {
            return this.$route.meta.dashboard || false;
        },
    },
    created() {
        this.emitter.on('payload_to_header', (payload) => {
            this.loading = payload.loading || false;
        });
    }
}
</script>

<style scoped lang="scss">
@import '../../../assets/styles/variables';

.custom_loading{
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 10000;
}

// X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap



/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {}
</style>