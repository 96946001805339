const ListaClienti = () => import('@/pages/clienti/ListaClienti.vue');
const MostraCliente = () => import('@/pages/clienti/MostraCliente.vue');
const CreaCliente = () => import('@/pages/clienti/CreaCliente.vue');
const PanoramicaCliente = () => import('@/pages/clienti/PanoramicaCliente.vue');
const ListaDuplicati = () => import('@/pages/clienti/duplicati/ListaDuplicati.vue');
const GestioneClientiDuplicati = () => import('@/pages/clienti/duplicati/GestioneClientiDuplicati.vue');

const routes = [
    { 
        name: 'gestione.cliente.duplicati',
        path: '/clienti/duplicati/gruppo/:gruppoId', 
        component: GestioneClientiDuplicati,
        meta: {
            titolo: 'Gestione Clienti Duplicati',
            requiresAuthentication: true,
            dashboard: false,
            permesso: 'cliente.duplicati'
        }
    },
    { 
        name: 'cliente.duplicati',
        path: '/clienti/duplicati', 
        component: ListaDuplicati,
        meta: {
            titolo: 'Lista Clienti Duplicati',
            requiresAuthentication: true,
            dashboard: false,
            permesso: 'cliente.duplicati'
        }
    },
    { 
        name: 'cliente.panoramica.index',
        path: '/panoramica/clienti', 
        component: PanoramicaCliente,
        meta: {
            titolo: 'Panoramica Clienti',
            requiresAuthentication: true,
            dashboard: false,
            permesso: 'cliente.panoramica'
        }
    },
    { 
        name: 'cliente.index',
        path: '/clienti', 
        component: ListaClienti,
        meta: {
            titolo: 'Lista Clienti',
            requiresAuthentication: true,
            dashboard: false,
            permesso: ['cliente.lista', 'cliente.utente.lista']
        }
    },
    { 
        name: 'cliente.show',
        path: '/cliente/:clienteId/show/:tab?', 
        component: MostraCliente,
        meta: {
            titolo: 'Cliente',
            requiresAuthentication: true,
            dashboard: false,
            permesso: 'cliente.show'
        }
    },
    { 
        name: 'cliente.create',
        path: '/cliente/create', 
        component: CreaCliente,
        meta: {
            titolo: 'Crea Cliente',
            requiresAuthentication: true,
            dashboard: false,
            permesso: 'cliente.create'
        }
    },
]

export default routes;