const calcolaDataSettimana = () => {
    const oggi = new Date();

    let giornoSettimana = oggi.getDay();    // Domenica = 0, Lunedì = 1, ecc.
    let differenzaAlLunedi = giornoSettimana === 0 ? -6 : 1 - giornoSettimana;  // Se oggi è domenica, sottrai 6 giorni

    // Calcola il lunedì della settimana corrente
    let lunedi = new Date(oggi);
    lunedi.setDate(oggi.getDate() + differenzaAlLunedi);

    // Calcola la domenica della settimana corrente aggiungendo 6 giorni al lunedì
    let domenica = new Date(lunedi);
    domenica.setDate(lunedi.getDate() + 6);

    return [lunedi, domenica];
};

export default calcolaDataSettimana;