const generaFoldername = (prefix) => {
    let foldername = null;

    if (prefix) {
        const correction = {
            '/': "_",
            '\\': "_",
            ':': "_",
            '*': "_",
            '?': "_",
            '"': "_"
        };
        Object.keys(correction).forEach((key) => {
            foldername = prefix.replaceAll(key, correction[key]);
        });
        foldername = foldername.toLowerCase();
        foldername = foldername.replaceAll(' ', '_');
    }

    return foldername;
};

export default generaFoldername;