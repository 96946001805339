const PaginaPromoter = () => import('@/pages/promoter/PaginaPromoter.vue');


const routes = [
    { 
        name: 'promoter.index',
        path: '/promoter', 
        component: PaginaPromoter,
        meta: {
            titolo: 'Pagina Promoter',
            requiresAuthentication: true,
            dashboard: false
        }
    },
]

export default routes;