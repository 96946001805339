const PaginaLogin = () => import('@/pages/authentication/PaginaLogin.vue');
const AfterLoginLoadUserData = () => import('@/pages/authentication/AfterLoginLoadUserData.vue');
const AfterLogoutResetStore = () => import('@/pages/authentication/AfterLogoutResetStore.vue');
const PaginaRegistrazione = () => import('@/pages/authentication/PaginaRegistrazione.vue');

const routes = [
    {
        name: 'auth.login',
        path: '/login',
        component: PaginaLogin,
        meta: {
            titolo: 'Accedi',
            requiresAuthentication: false,
            dashboard: false
        }
    },
    {
        name: 'auth.after.logout',
        path: '/after/logout',
        component: AfterLogoutResetStore,
        meta: {
            titolo: 'Disconnessione',
            requiresAuthentication: false,
            dashboard: false
        }
    },
    {
        name: 'auth.load.user',
        path: '/load/user/data',
        component: AfterLoginLoadUserData,
        meta: {
            titolo: '',
            requiresAuthentication: false,
            dashboard: false
        }
    },
    {
        name: 'auth.signin',
        path: '/signin',
        component: PaginaRegistrazione,
        meta: {
            titolo: 'Registrati',
            requiresAuthentication: false,
            dashboard: false
        }
    },
]

export default routes;