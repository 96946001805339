const ListaUtente = () => import('@/pages/dashboard_globale/generale/utenti/ListaUtente.vue');
const CreaUtente = () => import('@/pages/dashboard_globale/generale/utenti/CreaUtente.vue');
const ModificaUtente = () => import('@/pages/dashboard_globale/generale/utenti/ModificaUtente.vue');


const routes = [
    {
        name: 'utente.index',
        path: '/dashboard/utente',
        component: ListaUtente,
        meta: {
            titolo: 'Utente',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'utente.create',
        path: '/dashboard/utente/create',
        component: CreaUtente,
        meta: {
            titolo: 'Crea Utente',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'utente.edit',
        path: '/dashboard/utente/:utenteId/edit',
        component: ModificaUtente,
        meta: {
            titolo: 'Modifica Utente',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;