const ListaTipologiaDocumento = () => import('@/pages/dashboard_globale/area/amministrazione/tipologiaDocumento/ListaTipologiaDocumento.vue');
const CreaTipologiaDocumento = () => import('@/pages/dashboard_globale/area/amministrazione/tipologiaDocumento/CreaTipologiaDocumento.vue');
const ModificaTipologiaDocumento = () => import('@/pages/dashboard_globale/area/amministrazione/tipologiaDocumento/ModificaTipologiaDocumento.vue');

const routes = [
    {
        name: 'tipologia.documento.index',
        path: '/dashboard/tipologia/documento',
        component: ListaTipologiaDocumento,
        meta: {
            titolo: 'Tipologia Documento',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'tipologia.documento.create',
        path: '/dashboard/tipologia/documento/create',
        component: CreaTipologiaDocumento,
        meta: {
            titolo: 'Crea Tipologia Documento',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'tipologia.documento.edit',
        path: '/dashboard/tipologia/documento/:id/edit',
        component: ModificaTipologiaDocumento,
        meta: {
            titolo: 'Modifica Tipologia Documento',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;