const ListaPagamento = () => import('@/pages/dashboard_globale/area/amministrazione/modalitaPagamento/ListaPagamento.vue');
const CreaPagamento = () => import('@/pages/dashboard_globale/area/amministrazione/modalitaPagamento/CreaPagamento.vue');
const ModificaPagamento = () => import('@/pages/dashboard_globale/area/amministrazione/modalitaPagamento/ModificaPagamento.vue');

const routes = [
    {
        name: 'modalita.pagamento.index',
        path: '/dashboard/modalita/pagamento',
        component: ListaPagamento,
        meta: {
            titolo: 'Modalità Pagamento',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'modalita.pagamento.create',
        path: '/dashboard/modalita/pagamento/create',
        component: CreaPagamento,
        meta: {
            titolo: 'Crea Modalità Pagamento',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'modalita.pagamento.edit',
        path: '/dashboard/modalita/pagamento/:modalitaId/edit',
        component: ModificaPagamento,
        meta: {
            titolo: 'Modifica Modalità Pagamento',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;