const onlyStringTime = (oraDaFormattare) => {
    if (oraDaFormattare) {
        // Estrai solo l'ora e i minuti
        const [ore, minuti] = oraDaFormattare.split(':');
        return `${ore}:${minuti}`;
    } else {
        return '';
    }
};

export default onlyStringTime;