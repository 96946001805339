const CreaCategoriaProdottoCommerciale = () => import('@/pages/dashboard_globale/area/amministrazione/prodotto/categoria/CreaCategoriaProdotto.vue');
const ListaCategorieProdottiCommerciale = () => import('@/pages/dashboard_globale/area/amministrazione/prodotto/categoria/ListaCategorieProdotti.vue');
const ModificaCategoriaProdottoCommerciale = () => import('@/pages/dashboard_globale/area/amministrazione/prodotto/categoria/ModificaCategoriaProdotto.vue');

const routes = [
    {
        name: 'categorie.prodotti.commerciale.index',
        path: '/dashboard/categorie/prodotti/commerciale',
        component: ListaCategorieProdottiCommerciale,
        meta: {
            titolo: 'Categoria Prodotti Commerciale',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'categoria.prodotto.commerciale.create',
        path: '/dashboard/categoria/prodotto/commerciale/create',
        component: CreaCategoriaProdottoCommerciale,
        meta: {
            titolo: 'Crea Categoria Prodotto Commerciale',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'categoria.prodotto.commerciale.edit',
        path: '/dashboard/categoria/prodotto/commerciale/:categoriaId/edit',
        component: ModificaCategoriaProdottoCommerciale,
        meta: {
            titolo: 'Modifica Categoria Prodotto Commerciale',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;