const ListaStato = () => import('@/pages/dashboard_globale/stati/assistenza/ListaStato.vue');
const CreaStato = () => import('@/pages/dashboard_globale/stati/assistenza/CreaStato.vue');
const ModificaStato = () => import('@/pages/dashboard_globale/stati/assistenza/ModificaStato.vue');

const ListaGruppiStato = () => import('@/pages/dashboard_globale/stati/assistenza/gruppo/ListaGruppiStato.vue');
const CreaGruppoStati = () => import('@/pages/dashboard_globale/stati/assistenza/gruppo/CreaGruppoStati.vue');
const ModificaGruppoStati = () => import('@/pages/dashboard_globale/stati/assistenza/gruppo/ModificaGruppoStati.vue');

const routes = [
    {
        name: 'gruppi.stato.assistenza.index',
        path: '/dashboard/gruppi/stato/assistenza',
        component: ListaGruppiStato,
        meta: {
            titolo: 'Gruppi Stato Assistenza',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'gruppo.stati.assistenza.create',
        path: '/dashboard/gruppo/stati/assistenza/create',
        component: CreaGruppoStati,
        meta: {
            titolo: 'Crea Gruppo per Stato Assistenza',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'gruppo.stati.assistenza.edit',
        path: '/dashboard/gruppo/stati/assistenza/:id/edit',
        component: ModificaGruppoStati,
        meta: {
            titolo: 'Modifica Gruppo Stato Assistenza',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'stato.assistenza.index',
        path: '/dashboard/stato/assistenza',
        component: ListaStato,
        meta: {
            titolo: 'Stato Assistenza',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'stato.assistenza.create',
        path: '/dashboard/stato/assistenza/create',
        component: CreaStato,
        meta: {
            titolo: 'Crea Stato Assistenza',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'stato.assistenza.edit',
        path: '/dashboard/stato/assistenza/:id/edit',
        component: ModificaStato,
        meta: {
            titolo: 'Modifica Stato Assistenza',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;