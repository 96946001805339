const ListaTipologia = () => import('@/pages/dashboard_globale/area/assistenza/tipologia/ListaTipologia.vue');
const CreaTipologia = () => import('@/pages/dashboard_globale/area/assistenza/tipologia/CreaTipologia.vue');
const ModificaTipologia = () => import('@/pages/dashboard_globale/area/assistenza/tipologia/ModificaTipologia.vue');

const routes = [
    {
        name: 'tipologia.assistenza.index',
        path: '/dashboard/tipologia/assistenza',
        component: ListaTipologia,
        meta: {
            titolo: 'Tipologia Assistenza',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'tipologia.assistenza.create',
        path: '/dashboard/tipologia/assistenza/create',
        component: CreaTipologia,
        meta: {
            titolo: 'Crea Tipologia Assistenza',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'tipologia.assistenza.edit',
        path: '/dashboard/tipologia/assistenza/:id/edit',
        component: ModificaTipologia,
        meta: {
            titolo: 'Modifica Tipologia Assistenza',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;