const ListaTipologiaIndirizzo = () => import('@/pages/dashboard_globale/manutenzione/liste/tipologiaIndirizzo/ListaTipologiaIndirizzo.vue');
const CreaTipologiaIndirizzo = () => import('@/pages/dashboard_globale/manutenzione/liste/tipologiaIndirizzo/CreaTipologiaIndirizzo.vue');
const ModificaTipologiaIndirizzo = () => import('@/pages/dashboard_globale/manutenzione/liste/tipologiaIndirizzo/ModificaTipologiaIndirizzo.vue');

const routes = [
    {
        name: 'tipologia.indirizzo.index',
        path: '/dashboard/tipologia/indirizzo',
        component: ListaTipologiaIndirizzo,
        meta: {
            titolo: 'Tipologia Indirizzo',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'tipologia.indirizzo.create',
        path: '/dashboard/tipologia/indirizzo/create',
        component: CreaTipologiaIndirizzo,
        meta: {
            titolo: 'Crea Tipologia Indirizzo',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'tipologia.indirizzo.edit',
        path: '/dashboard/tipologia/indirizzo/:tipologiaId/edit',
        component: ModificaTipologiaIndirizzo,
        meta: {
            titolo: 'Modifica Tipologia Indirizzo',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;