const ListaTipologiaInstallazione = () => import('@/pages/dashboard_globale/area/installazione/tipologia/ListaTipologiaInstallazione.vue');
const CreaTipologiaInstallazione = () => import('@/pages/dashboard_globale/area/installazione/tipologia/CreaTipologiaInstallazione.vue');
const ModificaTipologiaInstallazione = () => import('@/pages/dashboard_globale/area/installazione/tipologia/ModificaTipologiaInstallazione.vue');

const routes = [
    {
        name: 'tipologia.installazione.index',
        path: '/dashboard/tipologia/installazione',
        component: ListaTipologiaInstallazione,
        meta: {
            titolo: 'Tipologia Installazione',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'tipologia.installazione.create',
        path: '/dashboard/tipologia/installazione/create',
        component: CreaTipologiaInstallazione,
        meta: {
            titolo: 'Crea Tipologia Installazione',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'tipologia.installazione.edit',
        path: '/dashboard/tipologia/installazione/:tipologiaId/edit',
        component: ModificaTipologiaInstallazione,
        meta: {
            titolo: 'Modifica Tipologia Installazione',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;