const GestioneLeads = () => import('@/pages/dashboard_globale/servizi/leads/GestioneLeads.vue');
const PanoramicaLeads = () => import('@/pages/dashboard_globale/servizi/leads/PanoramicaLeads.vue');
const ProcedureIsolate = () => import('@/pages/dashboard_globale/servizi/procedure/ProcedureIsolate.vue');

const routes = [
    {
        name: 'procedure.isolate',
        path: '/dashboard/procedure/isolate',
        component: ProcedureIsolate,
        meta: {
            titolo: 'Procedure Isolate',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'gestione.leads.index',
        path: '/dashboard/gestione/leads',
        component: GestioneLeads,
        meta: {
            titolo: 'Gestione Leads',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'panoramica.leads.index',
        path: '/dashboard/panoramica/leads',
        component: PanoramicaLeads,
        meta: {
            titolo: 'Panoramica Leads',
            requiresAuthentication: true,
            dashboard: true
        }
    },
];

export default routes;