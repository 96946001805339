const ListaTipologiaOrigine = () => import('@/pages/dashboard_globale/manutenzione/origine/tipologia/ListaTipologiaOrigine.vue');
const CreaTipologiaOrigine = () => import('@/pages/dashboard_globale/manutenzione/origine/tipologia/CreaTipologiaOrigine.vue');
const ModificaTipologiaOrigine = () => import('@/pages/dashboard_globale/manutenzione/origine/tipologia/ModificaTipologiaOrigine.vue');

const routes = [
    {
        name: 'tipologia.origine.index',
        path: '/dashboard/tipologia/origine',
        component: ListaTipologiaOrigine,
        meta: {
            titolo: 'Tipologia Origine',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'tipologia.origine.create',
        path: '/dashboard/tipologia/origine/create',
        component: CreaTipologiaOrigine,
        meta: {
            titolo: 'Crea Tipologia Origine',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'tipologia.origine.edit',
        path: '/dashboard/tipologia/origine/:id/edit',
        component: ModificaTipologiaOrigine,
        meta: {
            titolo: 'Modifica Tipologia Origine',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;