const StatisticheStands = () => import('@/pages/statistiche/StatisticheStands');

const routes = [
    { 
        name: 'statistiche.origine',
        path: '/statistiche/origine', 
        component: StatisticheStands,
        meta: {
            titolo: 'Statistiche Origine',
            requiresAuthentication: true,
            dashboard: false,
            permesso: 'statistiche.origine'
        }
    },
]

export default routes;