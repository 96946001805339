const onlyTime = (data) => {
    if (data) {
        const toFormat = new Date(data);
        let hh = toFormat.getHours().toString().padStart(2, '0');
        let min = toFormat.getMinutes().toString().padStart(2, '0');

        const formatted = hh + ':' + min;
        return formatted;
    } else {
        return null;
    }

};

export default onlyTime;