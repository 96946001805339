const ListaPratica = () => import('@/pages/dashboard_globale/manutenzione/liste/pratica/ListaPratica.vue');
const CreaPratica = () => import('@/pages/dashboard_globale/manutenzione/liste/pratica/CreaPratica.vue');
const ModificaPratica = () => import('@/pages/dashboard_globale/manutenzione/liste/pratica/ModificaPratica.vue');

const routes = [
    {
        name: 'pratica.index',
        path: '/dashboard/pratica',
        component: ListaPratica,
        meta: {
            titolo: 'Pratica',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'pratica.create',
        path: '/dashboard/pratica/create',
        component: CreaPratica,
        meta: {
            titolo: 'Crea Pratica',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'pratica.edit',
        path: '/dashboard/pratica/:praticaId/edit',
        component: ModificaPratica,
        meta: {
            titolo: 'Modifica Pratica',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;