import { createApp, defineAsyncComponent } from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

import App from './App.vue';
import router from '../routes/router';
import axios from './helpers/api.helper';
import mitt from 'mitt';  // Event Bus
const emitter = mitt();

import moshaToast from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import '@mdi/font/css/materialdesignicons.css';

import 'datatables.net-buttons/js/buttons.html5.mjs';
import jszip from 'jszip';
import pdfmake from 'pdfmake';

window.JSZip = jszip; // Imposta JSZip come variabile globale
window.pdfMake = pdfmake; // Imposta pdfmake come variabile globale

import DataTable from 'datatables.net-vue3';
import DataTablesLib from 'datatables.net-bs5';
import DataTablesButtons from 'datatables.net-buttons-dt'; 
import DataTablesColReorder from 'datatables.net-colreorder-dt';
import DataTablesRowGroup from 'datatables.net-rowgroup-dt';
import DataTablesResponsive from 'datatables.net-responsive-dt'; 
import DataTablesSelect from 'datatables.net-select-dt'; 

DataTable.use(DataTablesLib);
DataTable.use(DataTablesButtons);
DataTable.use(DataTablesColReorder);
DataTable.use(DataTablesRowGroup);
DataTable.use(DataTablesResponsive);
DataTable.use(DataTablesSelect);

import "vue-simple-calendar/dist/style.css";
import 'vue-simple-calendar/dist/css/default.css';

// Components
const TheNavbar = defineAsyncComponent(() => import('./components/UI/layout/TheNavbar.vue'));
const TheTitle = defineAsyncComponent(() => import('./components/UI/TheTitle.vue'));
const TitoloSezione = defineAsyncComponent(() => import('./components/UI/TitoloSezione.vue'));
const TheBreadcrumb = defineAsyncComponent(() => import('./components/UI/TheBreadcrumb.vue'));
const BaseCard = defineAsyncComponent(() => import('./components/UI/cards/BaseCard.vue'));
const MediumCard = defineAsyncComponent(() => import('./components/UI/cards/MediumCard.vue'));
const ButtonBreadcrumb = defineAsyncComponent(() => import('./components/UI/buttons/ButtonBreadcrumb.vue'));
const BaseButton = defineAsyncComponent(() => import('./components/UI/buttons/BaseButton.vue'));
const EventPanel = defineAsyncComponent(() => import('./components/UI/panel/EventPanel.vue'));
const SimplePanel = defineAsyncComponent(() => import('./components/UI/panel/SimplePanel.vue'));
const TheCheckbox = defineAsyncComponent(() => import('./components/UI/input/default/TheCheckbox.vue'));
const TheTextField = defineAsyncComponent(() => import('./components/UI/input/default/TheTextField.vue'));
const TheAlert = defineAsyncComponent(() => import('./components/UI/alerts/TheAlert.vue'));

// Vuetify
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as allComponents from 'vuetify/components';

const components = {...allComponents};    // allComponents is read-only
delete components.VTooltip;
delete components.VMenu;

import { VTooltip as VuetifyTooltip, VMenu as VuetifyMenu } from 'vuetify/components';
components.VuetifyTooltip = VuetifyTooltip;
components.VuetifyMenu = VuetifyMenu;

import * as directives from 'vuetify/directives';

const vuetify = createVuetify({
  components,
  directives,
  iconfont: 'mdi'
});

// Importazione Helpers
import capitalize from './helpers/utility/capitalize.helper';
import generaUsername from './helpers/utility/generaUsername.helper';
import generaFilename from './helpers/utility/generaFilename.helper';
import generaFoldername from './helpers/utility/generaFoldername.helper';
import getIconFromExtension from './helpers/utility/getIconForExtension.helper';
import formatDate from './helpers/utility/formatDate.helper';           // TODO Delete, vedi cartella utility/format.data
import shortData from './helpers/utility/format.data/shortData';
import shortDataTime from './helpers/utility/format.data/shortDataTime';
import longData from './helpers/utility/format.data/longData';
import longDataTime from './helpers/utility/format.data/longDataTime';
import onlyTime from './helpers/utility/format.data/onlyTime';
import onlyStringTime from './helpers/utility/format.data/onlyStringTime';
import calcolaData from './helpers/utility/format.data/calcolaData';
import dataForDB from './helpers/utility/format.data/dataForDB';
import dataTimeForDB from './helpers/utility/format.data/dataTimeForDB';
import calcolaDataSettimana from './helpers/utility/format.data/calcolaDataSettimana';
import calcolaDataMese from './helpers/utility/format.data/calcolaDataMese';

import { clerkPlugin } from 'vue-clerk';

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const app = createApp(App);

app.use(pinia);

app.use(router);
app.use(vuetify);

app.use(moshaToast);
app.component('DataTable', DataTable);
app.component('the-navbar', TheNavbar);
app.component('the-title', TheTitle);
app.component('titolo-sezione', TitoloSezione);
app.component('the-breadcrumb', TheBreadcrumb);
app.component('base-card', BaseCard);
app.component('medium-card', MediumCard);
app.component('button-breadcrumb', ButtonBreadcrumb);
app.component('base-button', BaseButton);
app.component('event-panel', EventPanel);
app.component('simple-panel', SimplePanel);
app.component('the-checkbox', TheCheckbox);
app.component('the-text-field', TheTextField);
app.component('the-alert', TheAlert);


app.mixin({
  methods: {
    capitalize, generaUsername, generaFilename, generaFoldername, getIconFromExtension, 
    formatDate, shortData, shortDataTime, longData, longDataTime, onlyTime, onlyStringTime, calcolaData, dataForDB, dataTimeForDB, calcolaDataSettimana, calcolaDataMese
  }
});

app.use(clerkPlugin, {
  publishableKey: process.env.VUE_APP_CLERK_PUBLISHABLE_KEY || ''
});

app.config.globalProperties.$http = axios;
app.config.globalProperties.emitter = emitter;

app.mount('#app');
