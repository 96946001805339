const ListaGruppoCategoriaDocumenti = () => import('@/pages/dashboard_globale/manutenzione/documenti/gruppo/ListaGruppo.vue');
const CreaGruppoCategoriaDocumenti = () => import('@/pages/dashboard_globale/manutenzione/documenti/gruppo/CreaGruppo.vue');
const ModificaGruppoCategoriaDocumenti = () => import('@/pages/dashboard_globale/manutenzione/documenti/gruppo/ModificaGruppo.vue');

const routes = [
    {
        name: 'gruppo.categoria.documenti.index',
        path: '/dashboard/gruppo/categoria/documenti',
        component: ListaGruppoCategoriaDocumenti,
        meta: {
            titolo: 'Gruppi Categorie Documenti',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'gruppo.categoria.documenti.create',
        path: '/dashboard/gruppo/categoria/documenti/create',
        component: CreaGruppoCategoriaDocumenti,
        meta: {
            titolo: 'Crea Gruppo Categoria Documenti',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'gruppo.categoria.documenti.edit',
        path: '/dashboard/gruppo/categoria/documenti/:gruppoId/edit',
        component: ModificaGruppoCategoriaDocumenti,
        meta: {
            titolo: 'Modifica Gruppo Categoria Documenti',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;