import axios from 'axios';

const baseUrl = process.env.VUE_APP_API_URL || 'https://api.cdmsell.it';

// Crea un'istanza Axios per gestire le configurazioni specifiche
const apiClient = axios.create({
    baseUrl: baseUrl,
    /* baseURL: 'https://api.cdmsell.it/', */
    /* baseURL: 'http://localhost:3000/', */
    withCredentials: true // Importante per assicurare che i cookie vengano inviati con le richieste
});

apiClient.interceptors.request.use(config => {
    const token = document.cookie.split('; ').find(row => row.startsWith('__session='))?.split('=')[1];

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

export default apiClient;