const elementi = [
    {
        tipologia: 'sezione',
        value: 'generale',
        titolo: 'Generale',
        items: [
            {
                tipologia: 'item',
                value: 'agenzie',
                titolo: 'Agenzie',
                icona: 'bi-buildings-fill',
                route: {
                    name: 'agenzia.index'
                }
            },
            {
                tipologia: 'item',
                value: 'gruppiUtente',
                titolo: 'Gruppi Utente',
                icona: 'bi-people-fill',
                route: {
                    name: 'gruppo.index'
                }
            },
            {
                tipologia: 'item',
                value: 'utenti',
                titolo: 'Utenti',
                icona: 'bi-person-fill',
                route: {
                    name: 'utente.index'
                }
            },
            {
                tipologia: 'item',
                value: 'qualifiche',
                titolo: 'Qualifiche',
                icona: 'bi-award-fill',
                route: {
                    name: 'qualifica.index'
                }
            },
        ]
    },
    {
        tipologia: 'sezione',
        value: 'stati',
        titolo: 'Stati',
        items: [
            {
                tipologia: 'gruppo',
                value: 'cliente',
                titolo: 'Cliente',
                icona: 'bi-dash',
                items: [
                    {
                        value: 'cliente_gruppi',   // FORMATO "gruppo_upToYou"
                        titolo: 'Gruppi Stato',
                        icona: 'bi-collection',
                        route: {
                            name: 'gruppi.stato.cliente.index'
                        }
                    },
                    {
                        value: 'cliente_stati',   // FORMATO "gruppo_upToYou"
                        titolo: 'Stati',
                        icona: 'bi-app',
                        route: {
                            name: 'stati.cliente.index'
                        }
                    },
                    {
                        value: 'cliente_stati_multipli',   // FORMATO "gruppo_upToYou"
                        titolo: 'Lista Multipla',
                        icona: 'bi-collection-fill',
                        route: {
                            name: 'lista.stato.cliente.index'
                        }
                    },
                ]
            },
            {
                tipologia: 'gruppo',
                value: 'sopralluogoStati',
                titolo: 'Sopralluogo',
                icona: 'bi-dash',
                items: [
                    {
                        value: 'sopralluogoStati_gruppi',   // FORMATO "gruppo_upToYou"
                        titolo: 'Gruppi Stato',
                        icona: 'bi-collection',
                        route: {
                            name: 'gruppi.stato.sopralluogo.index'
                        }
                    },
                    {
                        value: 'sopralluogoStati_stati',   // FORMATO "gruppo_upToYou"
                        titolo: 'Stati',
                        icona: 'bi-app',
                        route: {
                            name: 'stati.sopralluogo.index'
                        }
                    },
                ]
            },
            {
                tipologia: 'gruppo',
                value: 'ordine',
                titolo: 'Ordine',
                icona: 'bi-dash',
                items: [
                    {
                        value: 'ordine_gruppi',   // FORMATO "gruppo_upToYou"
                        titolo: 'Gruppi Stato',
                        icona: 'bi-collection',
                        route: {
                            name: 'gruppi.stato.ordine.index'
                        }
                    },
                    {
                        value: 'ordine_stati',   // FORMATO "gruppo_upToYou"
                        titolo: 'Stati',
                        icona: 'bi-app',
                        route: {
                            name: 'stati.ordine.index'
                        }
                    },
                ]
            },

            {
                tipologia: 'gruppo',
                value: 'installazione',
                titolo: 'Installazione',
                icona: 'bi-dash',
                items: [
                    {
                        value: 'installazione_gruppi',   // FORMATO "gruppo_upToYou"
                        titolo: 'Gruppi Stato',
                        icona: 'bi-collection',
                        route: {
                            name: 'gruppi.stato.installazione.index'
                        }
                    },
                    {
                        value: 'installazione_stati',   // FORMATO "gruppo_upToYou"
                        titolo: 'Stati',
                        icona: 'bi-app',
                        route: {
                            name: 'stati.installazione.index'
                        }
                    },

                ]
            },

            {
                tipologia: 'gruppo',
                value: 'assistenza',
                titolo: 'Assistenza',
                icona: 'bi-dash',
                items: [
                    {
                        value: 'assistenza_gruppi',   // FORMATO "gruppo_upToYou"
                        titolo: 'Gruppi Stato',
                        icona: 'bi-collection',
                        route: {
                            name: 'gruppi.stato.assistenza.index'
                        }
                    },
                    {
                        value: 'assistenza_stati',   // FORMATO "gruppo_upToYou"
                        titolo: 'Stati',
                        icona: 'bi-app',
                        route: {
                            name: 'stato.assistenza.index'
                        }
                    },

                ]
            },
        ]
    },
    {
        tipologia: 'sezione',
        value: 'aree',
        titolo: 'Aree',
        items: [
            {
                tipologia: 'gruppo',
                value: 'sopralluogo',
                titolo: 'Sopralluogo',
                icona: 'bi-globe-europe-africa',
                items: [
                    {
                        value: 'amministrazione_tipologia_cliente',   // FORMATO "gruppo_upToYou"
                        titolo: 'Tipologia Cliente',
                        icona: 'bi-list-columns',
                        route: {
                            name: 'tipologia.cliente.index'
                        }
                    },
                    /* {
                        value: 'amministrazione_tipologia_accesso_contatore',   // FORMATO "gruppo_upToYou"
                        titolo: 'Tipologia Accesso Contattore',
                        icona: 'bi-list-columns',
                        route: {
                            name: 'tipologia.accesso.contattore.index'
                        }
                    },
                    {
                        value: 'amministrazione_fornitura_impianto',   // FORMATO "gruppo_upToYou"
                        titolo: 'Fornitura Impianto',
                        icona: 'bi-list-columns',
                        route: {
                            name: 'fornitura.impianto.index'
                        }
                    },
                    {
                        value: 'amministrazione_tipologia_tensione',   // FORMATO "gruppo_upToYou"
                        titolo: 'Tipologia Tensione',
                        icona: 'bi-list-columns',
                        route: {
                            name: 'tipologia.tensione.index'
                        }
                    },
                    {
                        value: 'amministrazione_sistema_alimentazione',   // FORMATO "gruppo_upToYou"
                        titolo: 'Sistema Alimentazione',
                        icona: 'bi-list-columns',
                        route: {
                            name: 'sistema.alimentazione.index'
                        }
                    },
                    {
                        value: 'amministrazione_destinazione_utilizzo',   // FORMATO "gruppo_upToYou"
                        titolo: 'Destinazione Utilizzo',
                        icona: 'bi-list-columns',
                        route: {
                            name: 'destinazione.utilizzo.index'
                        }
                    }, */
                ]
            },
            {
                tipologia: 'gruppo',
                value: 'amministrazione',
                titolo: 'Amministrazione',
                icona: 'bi-journal-medical',
                items: [
                    {
                        value: 'amministrazione_finanziarie',   // FORMATO "gruppo_upToYou"
                        titolo: 'Finanziarie',
                        icona: 'bi-bank',
                        route: {
                            name: 'finanziaria.index'
                        }
                    },
                    {
                        value: 'amministrazione_modalita_pagamento',   // FORMATO "gruppo_upToYou"
                        titolo: 'Modalità Pagamento',
                        icona: 'bi-cash-stack',
                        route: {
                            name: 'modalita.pagamento.index'
                        }
                    },
                    {
                        value: 'amministrazione_listini',   // FORMATO "gruppo_upToYou"
                        titolo: 'Listini',
                        icona: 'bi-card-list',
                        route: {
                            name: 'listino.index'
                        }
                    },
                    {
                        value: 'amministrazione_promozione',   // FORMATO "gruppo_upToYou"
                        titolo: 'Promozioni',
                        icona: 'mdi-file-percent-outline',
                        route: {
                            name: 'promozione.index'
                        }
                    },
                    {
                        value: 'amministrazione_gruppo_categoria_prodotto',   // FORMATO "gruppo_upToYou"
                        titolo: 'Tipologia Impianto',
                        icona: 'bi-folder',
                        route: {
                            name: 'gruppo.categoria.prodotto.commerciale.index'
                        }
                    },
                    {
                        value: 'amministrazione_categorieProdotti',   // FORMATO "gruppo_upToYou"
                        titolo: 'Categorie Prodotti',
                        icona: 'bi-collection',
                        route: {
                            name: 'categorie.prodotti.commerciale.index'
                        }
                    },
                    {
                        value: 'amministrazione_Prodotti',   // FORMATO "gruppo_upToYou"
                        titolo: 'Prodotti',
                        icona: 'bi-app',
                        route: {
                            name: 'prodotti.commerciale.index'
                        }
                    },
                    {
                        value: 'amministrazione_lista_tipologia_documento',   // FORMATO "gruppo_upToYou"
                        titolo: 'Tipologia Documento',
                        icona: 'bi-list-columns',
                        route: {
                            name: 'tipologia.documento.index'
                        }
                    },
                ]
            },
            {
                tipologia: 'gruppo',
                value: 'installazione',
                titolo: 'Installazione',
                icona: 'bi-nut',
                items: [
                    {
                        value: 'installazione_tipologia',   // FORMATO "gruppo_upToYou"
                        titolo: 'Tipologia',
                        icona: 'bi-tag',
                        route: {
                            name: 'tipologia.installazione.index'
                        }
                    },
                    {
                        value: 'installazione_categorieProdotti',   // FORMATO "gruppo_upToYou"
                        titolo: 'Categorie Prodotti',
                        icona: 'bi-collection',
                        route: {
                            name: 'categorie.prodotti.cantiere.index'
                        }
                    },
                    {
                        value: 'installazione_prodotti',   // FORMATO "gruppo_upToYou"
                        titolo: 'Prodotti',
                        icona: 'bi-app',
                        route: {
                            name: 'prodotti.cantiere.index'
                        }
                    },
                ]
            },
            {
                tipologia: 'gruppo',
                value: 'assistenza',
                titolo: 'Assistenza',
                icona: 'bi-ticket-detailed-fill',
                items: [
                    {
                        value: 'assistenza_tipologia',
                        titolo: 'Tipologia',
                        icona: 'bi-tag',
                        route: {
                            name: 'tipologia.assistenza.index'
                        }
                    },
                    {
                        value: 'assistenza_priority',
                        titolo: 'Priorità',
                        icona: 'bi-clock',
                        route: {
                            name: 'priority.assistenza.index'
                        }
                    },
                ]
            },
            {
                tipologia: 'gruppo',
                value: 'helpdesk',
                titolo: 'HelpDesk',
                icona: 'bi-person-raised-hand',
                items: [
                    {
                        value: 'helpdesk_stato_gruppo',
                        titolo: 'Gruppi Stato',
                        icona: 'bi-collection',
                        route: {
                            name: 'gruppi.stato.ticket.index'
                        }
                    },
                    {
                        value: 'helpdesk_stato',
                        titolo: 'Stati',
                        icona: 'bi-app',
                        route: {
                            name: 'stato.ticket.index'
                        }
                    },
                    {
                        value: 'helpdesk_priority',
                        titolo: 'Priority',
                        icona: 'bi-clock',
                        route: {
                            name: 'priority.ticket.index'
                        }
                    },
                    {
                        value: 'helpdesk_tipologia',
                        titolo: 'Tipologia',
                        icona: 'bi-tag',
                        route: {
                            name: 'tipologia.ticket.index'
                        }
                    },
                ]
            },
        ]
    },
    {
        tipologia: 'sezione',
        value: 'manutenzione',
        titolo: 'Manutenzione',
        items: [
            {
                tipologia: 'gruppo',
                value: 'liste',
                titolo: 'Liste',
                icona: 'bi-list-columns',
                items: [
                    {
                        value: 'liste_tipologiaContatto',   // FORMATO "gruppo_upToYou"
                        titolo: 'Tipologia Contatto',
                        icona: 'bi-tag',
                        route: {
                            name: 'tipologia.contatto.index'
                        }
                    },
                    {
                        value: 'liste_tipologiaIndirizzo',   // FORMATO "gruppo_upToYou"
                        titolo: 'Tipologia Indirizzo',
                        icona: 'bi-tag',
                        route: {
                            name: 'tipologia.indirizzo.index'
                        }
                    },
                    /* {
                        value: 'liste_pratiche',   // FORMATO "gruppo_upToYou"
                        titolo: 'Pratiche',
                        icona: 'bi-journal',
                        route: {
                            name: 'pratica.index'
                        }, 
                    }, */
                    {
                        value: 'liste_interesse',   // FORMATO "gruppo_upToYou"
                        titolo: 'Interesse',
                        icona: 'bi-chat-dots',
                        route: {
                            name: 'interesse.index'
                        }
                    },
                ]
            },
            {
                tipologia: 'gruppo',
                value: 'nota',
                titolo: 'Nota',
                icona: 'bi-sticky',
                items: [
                    {
                        value: 'nota_tipologia',   // FORMATO "gruppo_upToYou"
                        titolo: 'Tipologia Nota',
                        icona: 'bi-tag',
                        route: {
                            name: 'tipologia.nota.index'
                        }
                    },
                    {
                        value: 'nota_tabella',   // FORMATO "gruppo_upToYou"
                        titolo: 'Tabelle',
                        icona: 'bi-database',
                        route: {
                            name: 'tabella.nota.index'
                        }
                    },
                ]
            },
            {
                tipologia: 'gruppo',
                value: 'origine',
                titolo: 'Origine',
                icona: 'bi-umbrella',
                items: [
                    {
                        value: 'origine_origine',   // FORMATO "gruppo_upToYou"
                        titolo: 'Origine',
                        icona: 'bi-umbrella',
                        route: {
                            name: 'origine.index'
                        }
                    },
                    {
                        value: 'origine_tipologia',   // FORMATO "gruppo_upToYou"
                        titolo: 'Tipologia Origine',
                        icona: 'bi-tag',
                        route: {
                            name: 'tipologia.origine.index'
                        }
                    }
                ]
            },
            {
                tipologia: 'gruppo',
                value: 'documenti',
                titolo: 'Documenti',
                icona: 'bi-archive',
                items: [
                    {
                        value: 'documenti_gruppi',   // FORMATO "gruppo_upToYou"
                        titolo: 'Gruppi',
                        icona: 'bi-folder', // bi-collection per altri gruppi
                        route: {
                            name: 'gruppo.categoria.documenti.index'
                        }
                    },
                    {
                        value: 'documenti_categorie',   // FORMATO "gruppo_upToYou"
                        titolo: 'Categorie',
                        icona: 'bi-file-earmark',
                        route: {
                            name: 'categoria.documenti.index'
                        }
                    },
                ]
            },
            {
                tipologia: 'gruppo',
                value: 'viste',
                titolo: 'Viste',
                icona: 'bi-eyeglasses',
                items: [
                    {
                        value: 'viste_colonne',   // FORMATO "gruppo_upToYou"
                        titolo: 'Colonne',
                        icona: 'bi-layout-three-columns',
                        route: {
                            name: 'colonna.index'
                        }
                    },
                ]
            },
            {
                tipologia: 'gruppo',
                value: 'calendario',
                titolo: 'Calendario',
                icona: 'bi-calendar',
                items: [
                    /* {
                        value: 'calendario_calendario',   // FORMATO "gruppo_upToYou"
                        titolo: 'Calendario',
                        icona: 'bi-calendar2',
                        route: {
                            name: 'work.in.progress'
                        }
                    }, */
                    {
                        value: 'calendario_tipologia_evento',   // FORMATO "gruppo_upToYou"
                        titolo: 'Tipologia Evento',
                        icona: 'bi-calendar-event',
                        route: {
                            name: 'tipologia.evento.index'
                        }
                    },
                ]
            },
        ]
    },
    {
        tipologia: 'sezione',
        value: 'storico',
        titolo: 'Storico',
        items: [
            {
                tipologia: 'item',
                value: 'tabella',
                titolo: 'Tabelle',
                icona: 'bi-database',
                route: {
                    name: 'tabella.storico.index'
                }
            },

        ]
    },
    {
        tipologia: 'sezione',
        value: 'servizi',
        titolo: 'Servizi',
        items: [
            {
                tipologia: 'gruppo',
                value: 'helpdesk',
                titolo: 'Gestione HelpDesk',
                icona: 'bi-person-raised-hand',
                items: [
                    {
                        value: 'helpdesk_lista_ticket',
                        titolo: 'Lista Ticket',
                        icona: 'bi-ticket-detailed',
                        route: {
                            name: 'helpdesk.index'
                        }
                    },
                ]
            },
            {
                tipologia: 'item',
                value: 'gestione_leads',
                titolo: 'Gestione Leads',
                icona: 'bi-people',
                route: {
                    name: 'gestione.leads.index'
                }
            },
            {
                tipologia: 'item',
                value: 'panoramica_leads',
                titolo: 'Panoramica Leads',
                icona: 'bi-diagram-3',
                route: {
                    name: 'panoramica.leads.index'
                }
            },
            /* {
                tipologia: 'item',
                value: 'statistiche_leads',
                titolo: 'Statistiche Leads',
                icona: 'bi-graph-up-arrow',
                route: {
                    name: 'statistiche.leads.index'
                }
            }, */
            {
                tipologia: 'item',
                value: 'procedure_isolate',
                titolo: 'Procedure Isolate',
                icona: 'bi-alt',
                route: {
                    name: 'procedure.isolate'
                }
            },
        ]
    },
    {
        tipologia: 'sezione',
        value: 'sicurezza',
        titolo: 'Sicurezza',
        items: [
            {
                tipologia: 'item',
                value: 'permessi',
                titolo: 'Permessi',
                icona: 'bi-shield-lock',
                route: {
                    name: 'permesso.index'
                }
            },
            {
                tipologia: 'item',
                value: 'ruoli',
                titolo: 'Ruoli',
                icona: 'bi-shield',
                route: {
                    name: 'ruolo.index'
                }
            },
        ]
    },
    {
        tipologia: 'sezione',
        value: 'migrazione',
        titolo: 'Migrazione',
        items: [
            {
                tipologia: 'item',
                value: 'autenticazione',
                titolo: 'Autenticazione',
                icona: 'bi-person-bounding-box',
                route: {
                    name: 'funzioni.autenticazione'
                }
            },
            {
                tipologia: 'item',
                value: 'utente',
                titolo: 'Utente',
                icona: 'bi-person',
                route: {
                    name: 'funzioni.utente'
                }
            },
            {
                tipologia: 'item',
                value: 'dati',
                titolo: 'Dati',
                icona: 'bi-database-fill-gear',
                route: {
                    name: 'funzioni.dati'
                }
            },
        ]
    },
];

export default elementi;