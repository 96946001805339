const CreaListino = () => import('@/pages/dashboard_globale/area/amministrazione/listini/CreaListino.vue');
const ListaListini = () => import('@/pages/dashboard_globale/area/amministrazione/listini/ListaListini.vue');
const ModificaListino = () => import('@/pages/dashboard_globale/area/amministrazione/listini/ModificaListino.vue');

const routes = [
    {
        name: 'listino.index',
        path: '/dashboard/listini',
        component: ListaListini,
        meta: {
            titolo: 'Listini',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'listino.create',
        path: '/dashboard/listino/create',
        component: CreaListino,
        meta: {
            titolo: 'Crea Listino',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'listino.edit',
        path: '/dashboard/listino/:listinoId/edit',
        component: ModificaListino,
        meta: {
            titolo: 'Modifica Listino',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;