const ListaAssistenza = () => import('@/pages/assistenza/ListaAssistenza.vue');
const ModificaAssistenza = () => import('@/pages/assistenza/ModificaAssistenza.vue');
const CreaAssistenza = () => import('@/pages/assistenza/CreaAssistenza.vue');

const routes = [
    { 
        name: 'assistenza.index',
        path: '/assistenza', 
        component: ListaAssistenza,
        meta: {
            titolo: 'Chiamate Assistenza',
            requiresAuthentication: true,
            dashboard: false
        }
    },
    { 
        name: 'assistenza.edit',
        path: '/assistenza/:id/edit', 
        component: ModificaAssistenza,
        meta: {
            titolo: 'Modifica Assistenza',
            requiresAuthentication: true,
            dashboard: false
        }
    },
    { 
        name: 'assistenza.create',
        path: '/assistenza/create/:clienteId?/:ordineId?', 
        component: CreaAssistenza,
        meta: {
            titolo: 'Crea Assistenza',
            requiresAuthentication: true,
            dashboard: false
        }
    },
]

export default routes;