const shortData = data => {
    if (data) {
        const toFormat = new Date(data);
        const yyyy = toFormat.getFullYear();
        let mm = (toFormat.getMonth() + 1).toString().padStart(2, '0');     // Aggiunge uno zero iniziale se necessario
        let dd = toFormat.getDate().toString().padStart(2, '0');            // Aggiunge uno zero iniziale se necessario

        const formatted = dd + '/' + mm + '/' + yyyy;
        return formatted;
    } else {
        return null;
    }

};

export default shortData;