const ListaPriority = () => import('@/pages/dashboard_globale/area/assistenza/priority/ListaPriority.vue');
const CreaPriority = () => import('@/pages/dashboard_globale/area/assistenza/priority/CreaPriority.vue');
const ModificaPriority = () => import('@/pages/dashboard_globale/area/assistenza/priority/ModificaPriority.vue');

const routes = [
    {
        name: 'priority.assistenza.index',
        path: '/dashboard/priority/assistenza',
        component: ListaPriority,
        meta: {
            titolo: 'Priorità Assistenza',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'priority.assistenza.create',
        path: '/dashboard/priority/assistenza/create',
        component: CreaPriority,
        meta: {
            titolo: 'Crea Priorità Assistenza',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'priority.assistenza.edit',
        path: '/dashboard/priority/assistenza/:id/edit',
        component: ModificaPriority,
        meta: {
            titolo: 'Modifica Priorità Assistenza',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;