const ListaInteresse = () => import('@/pages/dashboard_globale/manutenzione/liste/interesse/ListaInteresse.vue');
const CreaInteresse = () => import('@/pages/dashboard_globale/manutenzione/liste/interesse/CreaInteresse.vue');
const ModificaInteresse = () => import('@/pages/dashboard_globale/manutenzione/liste/interesse/ModificaInteresse.vue');

const routes = [
    {
        name: 'interesse.index',
        path: '/dashboard/interesse',
        component: ListaInteresse,
        meta: {
            titolo: 'Interesse',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'interesse.create',
        path: '/dashboard/interesse/create',
        component: CreaInteresse,
        meta: {
            titolo: 'Crea Interesse',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'interesse.edit',
        path: '/dashboard/interesse/:interesseId/edit',
        component: ModificaInteresse,
        meta: {
            titolo: 'Modifica Interesse',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;