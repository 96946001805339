const TheLista = () => import('@/pages/dashboard_globale/stati/cliente/lista/TheLista.vue');
const CreaLista = () => import('@/pages/dashboard_globale/stati/cliente/lista/CreaLista.vue');
const ModificaLista = () => import('@/pages/dashboard_globale/stati/cliente/lista/ModificaLista.vue');

const routes = [
    {
        name: 'lista.stato.cliente.index',
        path: '/dashboard/lista/stato/cliente',
        component: TheLista,
        meta: {
            titolo: 'Lista Gruppi Stato Cliente',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'lista.stato.cliente.create',
        path: '/dashboard/lista/stato/cliente/create',
        component: CreaLista,
        meta: {
            titolo: 'Crea Lista Gruppo Stato Cliente',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'lista.stato.cliente.edit',
        path: '/dashboard/lista/stato/cliente/:id/edit',
        component: ModificaLista,
        meta: {
            titolo: 'Modifica Lista Gruppo Stato Cliente',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;