const ListaTipologiaContatto = () => import('@/pages/dashboard_globale/manutenzione/liste/tipologiaContatto/ListaTipologiaContatto.vue');
const CreaTipologiaContatto = () => import('@/pages/dashboard_globale/manutenzione/liste/tipologiaContatto/CreaTipologiaContatto.vue');
const ModificaTipologiaContatto = () => import('@/pages/dashboard_globale/manutenzione/liste/tipologiaContatto/ModificaTipologiaContatto.vue');

const routes = [
    {
        name: 'tipologia.contatto.index',
        path: '/dashboard/tipologia/contatto',
        component: ListaTipologiaContatto,
        meta: {
            titolo: 'Tipologia Contatto',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'tipologia.contatto.create',
        path: '/dashboard/tipologia/contatto/create',
        component: CreaTipologiaContatto,
        meta: {
            titolo: 'Crea Tipologia Contatto',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'tipologia.contatto.edit',
        path: '/dashboard/tipologia/contatto/:tipologiaId/edit',
        component: ModificaTipologiaContatto,
        meta: {
            titolo: 'Modifica Tipologia Contatto',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;