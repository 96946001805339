const PreferenzeUtente = () => import('@/pages/utente/preferenze/PreferenzeUtente.vue');
const CreaVista = () => import('@/pages/utente/viste/CreaVista.vue');
const ModificaVista = () => import('@/pages/utente/viste/ModificaVista.vue');

const routes = [
    { 
        name: 'utente.preferenze',
        path: '/preferenze', 
        component: PreferenzeUtente,
        meta: {
            titolo: 'Preferenze Utente',
            requiresAuthentication: true,
            dashboard: false
        }
    },

    { 
        name: 'vista.create',
        path: '/vista/create', 
        component: CreaVista,
        meta: {
            titolo: 'Crea Vista',
            requiresAuthentication: true,
            dashboard: false
        }
    },
    { 
        name: 'vista.edit',
        path: '/vista/:vistaId/edit', 
        component: ModificaVista,
        meta: {
            titolo: 'Modifica Vista',
            requiresAuthentication: true,
            dashboard: false
        }
    },
]

export default routes;