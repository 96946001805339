const capitalize = (str) => {
    if (str) {
        const myString = str;

        let result = [];
        let transform = true;
        for (let i = 0; i < myString.length; i++) {
            if (transform) {
                result.push(myString[i].toUpperCase());
                transform = false;
            } else {
                result.push(myString[i].toLowerCase());
            }
            if (myString.charCodeAt(i) === 32 || myString.charCodeAt(i) === 39) {
                transform = true;
            }
        }
        const strResult = result.join("");

        const arr = strResult.split(" ");
        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === 'Pda') {
                arr[i] = arr[i].toUpperCase();
            }
        }

        const union = arr.join(" ");

        return union;
    } else {
        return null;
    }
};

export default capitalize;