const ListaTabellaNote = () => import('@/pages/dashboard_globale/manutenzione/nota/tabella/ListaTabellaNote.vue');
const CreaTabellaNota = () => import('@/pages/dashboard_globale/manutenzione/nota/tabella/CreaTabellaNota.vue');
const ModificaTabellaNota = () => import('@/pages/dashboard_globale/manutenzione/nota/tabella/ModificaTabellaNota.vue');

const routes = [
    {
        name: 'tabella.nota.index',
        path: '/dashboard/tabella/note',
        component: ListaTabellaNote,
        meta: {
            titolo: 'Tabella Nota',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'tabella.nota.create',
        path: '/dashboard/tabella/nota/create',
        component: CreaTabellaNota,
        meta: {
            titolo: 'Crea Tabella Nota',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'tabella.nota.edit',
        path: '/dashboard/tabella/nota/:id/edit',
        component: ModificaTabellaNota,
        meta: {
            titolo: 'Modifica Tabella Nota',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;