const TheDashboard = () => import('@/pages/dashboard_globale/TheDashboard.vue');

const routes = [
    {
        name: 'dashboard.index',
        path: '/dashboard',
        component: TheDashboard,
        meta: {
            titolo: 'Dashboard',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;