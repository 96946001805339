<template>
    <div>
        <v-expand-transition mode="out-in">
            <div v-if="menuVisible">
                <v-container fluid class="selettore">
                    <div class="selettore_container">

                        <v-list v-model:opened="menu.open" class="selettore_container_list">

                            <v-list-group v-for="(gruppo, i) in menu.gruppi" :key="i" :value="gruppo.value">
                                <template v-slot:activator="{ props }">
                                    <v-list-item v-bind="props" v-if="hasPermesso(gruppo.permesso)"
                                        :prepend-icon="gruppo.icona" :title="gruppo.titolo" class="gruppo_text_white">
                                    </v-list-item>
                                </template>

                                <template v-for="(item, i) in gruppo.items">
                                    <template v-if="hasPermesso(item.permesso)" >
                                        <v-list-item :key="i" :value="item.value" :title="item.titolo"
                                            @click.prevent="handleNavigation(item.route, gruppo)"
                                            :prepend-icon="item.icona" class="item_text_white">
                                        </v-list-item>
                                    </template>
                                </template>

                            </v-list-group>

                            <template v-for="(item, i) in menu.items">
                                <template v-if="hasPermesso(item.permesso)" >
                                    <v-list-item :key="i" :value="item.value" :title="item.titolo"
                                        @click.prevent="handleNavigation(item.route, gruppo)" :prepend-icon="item.icona"
                                        class="item_text_white">
                                    </v-list-item>
                                </template>
                            </template>

                        </v-list>

                    </div>
                </v-container>
            </div>
        </v-expand-transition>

        <Teleport to="body">
            <div class="container_menu_activator">
                <div style="background-color: transparent; padding: 20px !important;">
                    <v-btn :icon="iconaForMenuActivator" @click.prevent="menuVisible = !menuVisible"
                        elevation="8"></v-btn>
                </div>
            </div>
        </Teleport>

    </div>
</template>

<script>
import { useDefaultStore } from '@/stores/defaultStore.store';

export default {
    data() {
        return {
            store: useDefaultStore(),

            menuVisible: true,
            menu: {
                open: [''],
                gruppi: [
                    {
                        value: 'amministrazione',
                        titolo: 'AMMINISTRAZIONE',
                        icona: 'bi-file-earmark-medical',
                        permesso: 'navigazione.amministrazione',
                        items: [
                            {
                                value: 'amministrazione_clienti',   // FORMATO "gruppo_upToYou"
                                titolo: 'Clienti',
                                icona: 'bi-person',
                                permesso: ['cliente.lista', 'cliente.utente.lista'],
                                route: {
                                    name: 'cliente.index'
                                }
                            },
                            /* {
                                value: 'amministrazione_panoramica',   // FORMATO "gruppo_upToYou"
                                titolo: 'Panoramica',
                                icona: 'bi-diagram-3',
                                permesso: 'cliente.panoramica',
                                route: {
                                    name: 'cliente.panoramica.index'
                                }
                            }, */
                            {
                                value: 'amministrazione_ordini',   // FORMATO "gruppo_upToYou"
                                titolo: 'Ordini',
                                icona: 'bi-file-earmark-binary',
                                permesso: ['ordine.index', 'ordine.utente.index'],
                                route: {
                                    name: 'ordine.index'
                                }
                            },
                            {
                                value: 'amministrazione_panoramica_cantieri',   // FORMATO "gruppo_upToYou"
                                titolo: 'Panoramica Cantieri',
                                icona: 'bi-view-stacked',
                                permesso: 'panoramica.cantieri',
                                route: {
                                    name: 'panoramica.cantieri'
                                }
                            },
                        ]
                    },
                    {
                        value: 'marketing',
                        titolo: 'MARKETING',
                        icona: 'bi-wallet2',
                        permesso: 'navigazione.marketing',
                        items: [
                            {
                                value: 'marketing_clienti',   // FORMATO "gruppo_upToYou"
                                titolo: 'Clienti',
                                icona: 'bi-person',
                                permesso: ['cliente.lista', 'cliente.utente.lista'],
                                route: {
                                    name: 'cliente.index'
                                }
                            },
                            /* {
                                value: 'marketing_panoramica',   // FORMATO "gruppo_upToYou"
                                titolo: 'Panoramica',
                                icona: 'bi-diagram-3',
                                permesso: 'cliente.panoramica',
                                route: {
                                    name: 'cliente.panoramica.index'
                                }
                            }, */
                            {
                                value: 'marketing_clienti_duplicati',   // FORMATO "gruppo_upToYou"
                                titolo: 'Duplicati',
                                icona: 'bi-people',
                                permesso: 'cliente.duplicati',
                                route: {
                                    name: 'cliente.duplicati'
                                }
                            },
                        ]
                    },
                    {
                        value: 'cantiere',
                        titolo: 'PROGETTAZIONE',
                        icona: 'bi-rulers',
                        permesso: 'navigazione.cantiere',
                        items: [
                            {
                                value: 'cantiere_clienti',   // FORMATO "gruppo_upToYou"
                                titolo: 'Clienti',
                                icona: 'bi-person',
                                permesso: ['cliente.lista', 'cliente.utente.lista'],
                                route: {
                                    name: 'cliente.index'
                                }
                            },
                            /* {
                                value: 'cantiere_panoramica',   // FORMATO "gruppo_upToYou"
                                titolo: 'Panoramica',
                                icona: 'bi-diagram-3',
                                permesso: 'cliente.panoramica',
                                route: {
                                    name: 'cliente.panoramica.index'
                                }
                            }, */
                            {
                                value: 'cantiere_sopralluogo',   // FORMATO "gruppo_upToYou"
                                titolo: 'Sopralluogo',
                                icona: 'bi-globe-europe-africa',
                                permesso: ['sopralluogo.index', 'sopralluogo.utente.index'],
                                route: {
                                    name: 'sopralluogo.index'
                                }
                            },
                            {
                                value: 'cantiere_installazioni',   // FORMATO "gruppo_upToYou"
                                titolo: 'Cantieri',
                                icona: 'bi-cone-striped',
                                permesso: ['installazione.index', 'installazione.utente.index'],
                                route: {
                                    name: 'installazione.index'
                                }
                            },
                        ]
                    },
                    {
                        value: 'statistiche',
                        titolo: 'STATISTICHE',
                        icona: 'bi-clipboard-data',
                        permesso: 'navigazione.statistiche',
                        items: [
                            {
                                value: 'statistiche_nominativi',   // FORMATO "gruppo_upToYou"
                                titolo: 'Nominativi',
                                icona: 'bi-umbrella',
                                permesso: 'statistiche.origine',
                                route: {
                                    name: 'statistiche.origine'
                                }
                            },
                        ]
                    },
                ],
                items: [
                    {
                        value: 'magazzino',
                        titolo: 'MAGAZZINO',
                        icona: 'bi-boxes',
                        permesso: 'navigazione.magazzino',
                        route: {
                            name: 'magazzino.index'
                        }
                    },
                    {
                        value: 'promoter',
                        titolo: 'PROMOTER',
                        icona: 'bi-headset',
                        permesso: 'navigazione.promoter',
                        route: {
                            name: 'promoter.index'
                        }
                    },
                    /* {
                        value: 'assistenza',
                        titolo: 'Assistenza',
                        icona: 'bi-ticket-detailed',
                        permesso: 'navigazione.assistenza',
                        route: {
                            name: 'assistenza.index'
                        }
                    }, */
                ]
            }
        };
    },
    methods: {
        hasPermesso(permesso) {
            return this.store.hasPermesso(permesso);
        },
        handleNavigation(route, gruppo) {
            this.$router.push(route);

            if (gruppo) {
                gruppo.value = false;
            }
        },
        daSviluppare() {
            this.moshaToast(
                {
                    title: 'Handle non Implementato!',
                    description: `Sviluppo EOS in Corso.`
                },
                {
                    showIcon: true,
                    hideProgressBar: false,
                    timeout: 6000,
                    type: 'info',
                    toastBackgroundColor: '#292b2c',
                    transition: 'bounce',
                    showCloseButton: true,
                    swipeClose: true,
                    position: 'bottom-right'
                }
            );
        },
    },
    computed: {
        iconaForMenuActivator() {
            return this.menuVisible ? 'bi-x' : 'bi-list';
        }
    }
}
</script>

<style scoped lang="scss">
@import '../../../assets/styles/variables';
/* X-Small devices (portrait phones, less than 576px)
No media query for `xs` since this is the default in Bootstrap */

.container_menu_activator {
    display: block;
    position: fixed;
    top: auto;
    bottom: 50px;
    right: 0px;
    z-index: 999;
}

.selettore {
    background-color: $primary !important;
    color: #fff;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .container_menu_activator {
        right: 40px;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .container_menu_activator {
        display: none;
        top: 32px;
        right: 0px;
        bottom: auto;
    }

    .selettore {
        align-items: center;
        justify-content: center;
        background-color: $primary;
        color: #fff;
    }

    .selettore_container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        margin: 2px 100px 0px 20px !important;
    }

    .selettore_container_list {
        display: flex;
        padding: 0 !important;
        background-color: transparent;
    }

    .gruppo_text_white {
        width: auto;
        min-width: max-content;
        height: 48px;
        color: #fff;
    }

    .item_text_white {
        width: auto;
        min-width: max-content;
        height: 48px;
        color: #fff;
    }
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {}
</style>