const ListaStatiCliente = () => import('@/pages/dashboard_globale/stati/cliente/stato/ListaStati.vue');
const CreaStatoCliente = () => import('@/pages/dashboard_globale/stati/cliente/stato/CreaStato.vue');
const ModificaStatoCliente = () => import('@/pages/dashboard_globale/stati/cliente/stato/ModificaStato.vue');

const routes = [
    {
        name: 'stati.cliente.index',
        path: '/dashboard/stati/cliente',
        component: ListaStatiCliente,
        meta: {
            titolo: 'Stati Cliente',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'stato.cliente.create',
        path: '/dashboard/stato/cliente/create',
        component: CreaStatoCliente,
        meta: {
            titolo: 'Crea Stato Cliente',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'stato.cliente.edit',
        path: '/dashboard/stato/cliente/:id/edit',
        component: ModificaStatoCliente,
        meta: {
            titolo: 'Modifica Stato Cliente',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;