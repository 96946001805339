const ListaGruppoCategoriaProdottoCommerciale = () => import('@/pages/dashboard_globale/area/amministrazione/gruppoCategoriaProdotto/ListaGruppo.vue');
const CreaGruppoCategoriaProdottoCommerciale = () => import('@/pages/dashboard_globale/area/amministrazione/gruppoCategoriaProdotto/CreaGruppo.vue');
const ModificaGruppoCategoriaProdottoCommerciale = () => import('@/pages/dashboard_globale/area/amministrazione/gruppoCategoriaProdotto/ModificaGruppo.vue');

const routes = [
    {
        name: 'gruppo.categoria.prodotto.commerciale.index',
        path: '/dashboard/gruppo/categoria/prodotto/commerciale',
        component: ListaGruppoCategoriaProdottoCommerciale,
        meta: {
            titolo: 'Gruppi Categorie Prodotto Commerciale',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'gruppo.categoria.prodotto.commerciale.create',
        path: '/dashboard/gruppo/categoria/prodotto/commerciale/create',
        component: CreaGruppoCategoriaProdottoCommerciale,
        meta: {
            titolo: 'Crea Gruppo Categoria Prodotto Commerciale',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'gruppo.categoria.prodotto.commerciale.edit',
        path: '/dashboard/gruppo/categoria/prodotto/commerciale/:gruppoId/edit',
        component: ModificaGruppoCategoriaProdottoCommerciale,
        meta: {
            titolo: 'Modifica Gruppo Categoria Prodotto Commerciale',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;