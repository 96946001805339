const ListaOrigine = () => import('@/pages/dashboard_globale/manutenzione/origine/ListaOrigine.vue');
const CreaOrigine = () => import('@/pages/dashboard_globale/manutenzione/origine/CreaOrigine.vue');
const ModificaOrigine = () => import('@/pages/dashboard_globale/manutenzione/origine/ModificaOrigine.vue');

const routes = [
    {
        name: 'origine.index',
        path: '/dashboard/origine',
        component: ListaOrigine,
        meta: {
            titolo: 'Origine',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'origine.create',
        path: '/dashboard/origine/create',
        component: CreaOrigine,
        meta: {
            titolo: 'Crea Origine',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'origine.edit',
        path: '/dashboard/origine/:origineId/edit',
        component: ModificaOrigine,
        meta: {
            titolo: 'Modifica Origine',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;