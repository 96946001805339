const ListaQualifica = () => import('@/pages/dashboard_globale/generale/qualifica/ListaQualifica.vue');
const CreaQualifica = () => import('@/pages/dashboard_globale/generale/qualifica/CreaQualifica.vue');
const ModificaQualifica = () => import('@/pages/dashboard_globale/generale/qualifica/ModificaQualifica.vue');

const routes = [
    {
        name: 'qualifica.index',
        path: '/dashboard/qualifica',
        component: ListaQualifica,
        meta: {
            titolo: 'Qualifica',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'qualifica.create',
        path: '/dashboard/qualifica/create',
        component: CreaQualifica,
        meta: {
            titolo: 'Crea Qualifica',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'qualifica.edit',
        path: '/dashboard/qualifica/:id/edit',
        component: ModificaQualifica,
        meta: {
            titolo: 'Modifica Qualifica',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;