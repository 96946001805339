const ListaGruppiStatoOrdine = () => import('@/pages/dashboard_globale/stati/ordine/gruppo/ListaGruppiStato.vue');
const CreaGruppoStatoOrdine = () => import('@/pages/dashboard_globale/stati/ordine/gruppo/CreaGruppoStati.vue');
const ModificaGruppoStatoOrdine = () => import('@/pages/dashboard_globale/stati/ordine/gruppo/ModificaGruppoStati.vue');

const routes = [
    {
        name: 'gruppi.stato.ordine.index',
        path: '/dashboard/gruppi/stato/ordine',
        component: ListaGruppiStatoOrdine,
        meta: {
            titolo: 'Gruppi Stato PDA',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'gruppo.stati.ordine.create',
        path: '/dashboard/gruppo/stati/ordine/create',
        component: CreaGruppoStatoOrdine,
        meta: {
            titolo: 'Crea Gruppo per Stati PDA',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'gruppo.stati.ordine.edit',
        path: '/dashboard/gruppo/stati/ordine/:id/edit',
        component: ModificaGruppoStatoOrdine,
        meta: {
            titolo: 'Modifica Gruppo Stati PDA',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;