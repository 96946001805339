import { createRouter, createWebHistory } from 'vue-router';
import { useDefaultStore } from '@/stores/defaultStore.store';

import authenticationRoutes from './authentication/authentication.routes';

// ================================== DASHBOARD GLOBALE ==================================
import dashboardRoutes from './dashboard_globale/dashboard.routes';

// SEZIONE Generale
import agenzia from './dashboard_globale/generale/agenzia.routes';
import gruppoUtente from './dashboard_globale/generale/gruppoUtente.routes';
import qualifica from './dashboard_globale/generale/qualifica.routes';
import utente from './dashboard_globale/generale/utente.routes';

// SEZIONE Stati
import gruppoStatoCliente from './dashboard_globale/stati/cliente/gruppo.routes';
import statoCliente from './dashboard_globale/stati/cliente/stato.routes';
import listaStatoCliente from './dashboard_globale/stati/cliente/lista.routes';
import statoSopralluogo from './dashboard_globale/stati/sopralluogo/stato.routes';
import gruppoStatoOrdine from './dashboard_globale/stati/ordine/gruppo.routes';
import statoOrdine from './dashboard_globale/stati/ordine/stato.routes';
import statoInstallazione from './dashboard_globale/stati/installazione/stato.routes';
import statoAssistenza from './dashboard_globale/stati/assistenza/stato.routes';

// SEZIONE Aree
import sopralluogoTipologiaClienteDashboard from './dashboard_globale/area/sopralluogo/tipologia.cliente.routes.js';
import amministrazioneFinanziariaDashboard from './dashboard_globale/area/amministrazione/finanziaria.routes.js';
import amministrazioneTipologiaDocumentoDashboard from './dashboard_globale/area/amministrazione/tipologia.documento.routes.js';
import gruppoCategoriaProdottoDashboard from './dashboard_globale/area/amministrazione/gruppo.categoria.prodotto.routes.js';
import amministrazionePromozioneashboard from './dashboard_globale/area/amministrazione/promozione.prodotto.routes.js';
import amministrazioneModalitaPagamentoDashboard from './dashboard_globale/area/amministrazione/modalita.pagamento.routes.js';

import categorieProdottoAmministrazione from './dashboard_globale/area/amministrazione/categorieProdotto.routes';
import prodottoAmministrazione from './dashboard_globale/area/amministrazione/prodotto.routes';
import listinoAmministrazione from './dashboard_globale/area/amministrazione/listino.routes';
import tipologiaAssistenza from './dashboard_globale/area/assistenza/tipologia.routes';
import priorityAssistenza from './dashboard_globale/area/assistenza/priority.routes';
import categorieProdottoInstallazione from './dashboard_globale/area/installazione/categorieProdotto.routes';
import prodottoInstallazione from './dashboard_globale/area/installazione/prodotto.routes';
import tipologiaInstallazione from './dashboard_globale/area/installazione/tipologia.routes';

import gruppoStatoTicketDashboard from './dashboard_globale/area/helpdesk/stato.gruppo.routes.js';
import statoTicketDashboard from './dashboard_globale/area/helpdesk/stato.routes.js';
import priorityTicketDashboard from './dashboard_globale/area/helpdesk/priority.routes.js';
import tipologiaTicketDashboard from './dashboard_globale/area/helpdesk/tipologia.routes.js';

// SEZIONE Manutenzione
import categoriaDocumenti from './dashboard_globale/manutenzione/documenti/categoria.routes';
import gruppoDocumenti from './dashboard_globale/manutenzione/documenti/gruppo.routes';
import praticaLista from './dashboard_globale/manutenzione/liste/pratica.routes';
import interesse from './dashboard_globale/manutenzione/liste/interesse.routes';
import origineDashboard from './dashboard_globale/manutenzione/origine/origine.routes';
import tipologiaOrigineDashboard from './dashboard_globale/manutenzione/origine/tipologia.routes';
import tipologiaContatto from './dashboard_globale/manutenzione/liste/tipologia.contatto.routes';
import tipologiaIndirizzo from './dashboard_globale/manutenzione/liste/tipologia.indirizzo.routes';
import tipologiaNota from './dashboard_globale/manutenzione/nota/tipologia.nota.routes';
import tabellaNota from './dashboard_globale/manutenzione/nota/tabella.nota.routes';
import colonna from './dashboard_globale/manutenzione/viste/colonna.routes';

// SEZIONE Servizi
import serviziDashboard from './dashboard_globale/servizi/servizi.routes';
import calendarioDashboard from './dashboard_globale/servizi/calendario.routes';
import helpdeskDashboard from './dashboard_globale/servizi/helpdesk.routes.js';

import versioneDashboard from './dashboard_globale/versione.routes';

// SEZIONE Dashboard - Sicurezza
import ruoloDashboard from './dashboard_globale/sicurezza/ruolo.routes';
import permessoDashboard from './dashboard_globale/sicurezza/permesso.routes';

// SEZIONE Storico
import tabellaStorico from './dashboard_globale/storico/tabella.storico.routes';

// SEZIONE Migrazione
import funzioniAutenticazione from './dashboard_globale/migrazione/autenticazione.routes.js';

// ================================== FRONTEND GLOBALE ==================================
import frontendRoutes from './frontend/frontend.routes.js';

import helpdeskRoutes from './frontend/helpdesk/helpdesk.routes.js';
import calendarioUtenteRoutes from './frontend/utente/calendario.routes';
import preferenzeUtenteRoutes from './frontend/utente/preferenze.routes';
import clienteRoutes from './frontend/core/cliente/cliente.routes';
import ordineRoutes from './frontend/core/ordine/ordine.routes';
import statisticheRoutes from './frontend/statistiche/statistiche.routes';
import installazioneRoutes from './frontend/core/installazione/installazione.routes';
import notificaRoutes from './frontend/notifica/notifica.routes';
import documentazioneRoutes from './frontend/documentazione/documentazione.routes';
import assistenzaRoutes from './frontend/assistenza/assistenza.routes';
import magazzinoRoutes from './frontend/magazzino/magazzino.routes';
import moduloRoutes from './frontend/modulo/modulo.routes';
import sopralluogoRoutes from './frontend/core/sopralluogo/sopralluogo.routes';
import promoterRoutes from './frontend/core/promoter/promoter.routes';

let allRoutes = [];
allRoutes = allRoutes.concat(
    authenticationRoutes,
    dashboardRoutes,
    // Sezione Generale
    agenzia,
    gruppoUtente,
    qualifica,
    utente,
    // Sezione Stati
    gruppoStatoCliente,
    statoCliente,
    listaStatoCliente,
    gruppoStatoOrdine,
    statoOrdine,
    // Sezione Aree
    sopralluogoTipologiaClienteDashboard,
    amministrazioneFinanziariaDashboard,
    categorieProdottoAmministrazione,
    prodottoAmministrazione,
    listinoAmministrazione,
    statoAssistenza,
    tipologiaAssistenza,
    priorityAssistenza,
    categorieProdottoInstallazione,
    prodottoInstallazione,
    statoInstallazione,
    tipologiaInstallazione,
    gruppoStatoTicketDashboard,
    statoTicketDashboard,
    priorityTicketDashboard,
    tipologiaTicketDashboard, 
    statoSopralluogo,
    amministrazioneTipologiaDocumentoDashboard,
    gruppoCategoriaProdottoDashboard, 
    amministrazionePromozioneashboard, 
    amministrazioneModalitaPagamentoDashboard, 
    // Manutenzione
    categoriaDocumenti,
    gruppoDocumenti,
    praticaLista,
    interesse,
    origineDashboard,
    tipologiaOrigineDashboard,
    tipologiaContatto,
    tipologiaIndirizzo,
    tipologiaNota,
    tabellaNota,
    colonna,
    // Servizi
    helpdeskDashboard,
    serviziDashboard,
    calendarioDashboard,
    versioneDashboard,
    // Sicurezza
    ruoloDashboard,
    permessoDashboard,
    // Storico
    tabellaStorico,
    // Migrazione
    funzioniAutenticazione,
    // Front
    frontendRoutes,
    helpdeskRoutes, 
    notificaRoutes,
    preferenzeUtenteRoutes,
    clienteRoutes,
    ordineRoutes,
    statisticheRoutes,
    installazioneRoutes,
    documentazioneRoutes,
    assistenzaRoutes,
    magazzinoRoutes,
    moduloRoutes,
    sopralluogoRoutes,
    promoterRoutes,
    calendarioUtenteRoutes,
);

const router = createRouter({
    history: createWebHistory(),
    routes: allRoutes
});

router.beforeEach(async (to, from, next) => {
    // Pesco i dati della route che sto per visitare
    const toMetaRequiresAuthentication = to.meta.requiresAuthentication;
    const toMetaDashboard = to.meta.dashboard;
    const toMetaPermesso = to.meta.permesso;

    // Pesco i dati dell'utente collegato dallo store
    const store = useDefaultStore();

    const userAuthenticated = store.isAuthenticated;
    const userDashboard = store.hasDashboard;

    if(toMetaRequiresAuthentication) {
        if(!userAuthenticated) {
            next({ path: '/login' });
            return;
        }
    }

    if(toMetaDashboard) {
        if(!userDashboard) {
            next({ path: '/' });
            return;
        }
    }

    if(toMetaPermesso) {
        const userHasPermesso = store.hasPermesso(toMetaPermesso);
        if(!userHasPermesso) {
            next({ path: '/' });
            return;
        }
    }

    next();
});

router.afterEach((to) => {
    const store = useDefaultStore();

    // TODO trasformare full path in oggetto route (che comprende anche il path)
    const urlToAdd = {
        titolo: to.meta.titolo ?? 'ND',
        fullPath: to.fullPath
    };
    store.addElementToBreadcrumb(urlToAdd);
});

export default router;