<template>
    <div style="height: 120px;"></div>
    <div class="my_footer">
        <v-container class="inside_container">
            <div class="info">
                <p><strong>© {{ new Date().getFullYear() }} - EOS</strong></p>
            </div>
            <div class="cit">

                <div v-if="cit" class="cit_content">
                    <p class="text-left">
                        <strong>"</strong>
                            {{ cit?.cit }}
                        <strong>"</strong>
                    </p>
                    <p class="text-right"><strong><i>{{ cit?.who }}</i></strong></p>
                </div>

            </div>
            <div class="versione">
                <p>
                    <v-icon icon="bi-git" style="font-size: 24px; margin-right: 12px;"></v-icon>
                    <strong>{{ versione }}</strong>
                </p>
                <!-- <v-btn variant="text" @click.prevent="goToVersionPage">
                    <v-icon icon="bi-git" style="font-size: 24px; margin-right: 6px;"></v-icon>
                    <vuetify-tooltip activator="parent" location="left">
                        {{ versione }}
                    </vuetify-tooltip>
                </v-btn> -->
            </div>
        </v-container>
    </div>
</template>

<script>
import apiMixin from '@/mixin/api.mixin';

import { useDefaultStore } from '@/stores/defaultStore.store';

export default {
    mixins: [apiMixin],
    inject: ['moshaToast'],
    data() {
        return {
            store: useDefaultStore(),
            versione: null,
            utente: null,

            loading: false,
            cit: null,
        }
    },
    methods: {
        async loadCitazione() {
            this.loading = true;

            try {
                const response = await this.$http.get(`${this.API_URL}/load/citazione`);
                if (response.status === 200) {
                    const dataResponse = response.data;
                    if (dataResponse) {
                        this.cit = dataResponse.data;

                        if (dataResponse.alert) {
                            this.moshaToast(
                                {
                                    title: dataResponse.title || ``,
                                    description: dataResponse.description || ``,
                                },
                                {
                                    showIcon: true,
                                    hideProgressBar: false,
                                    timeout: 6000,
                                    type: 'success',
                                    transition: 'bounce',
                                    showCloseButton: true,
                                    swipeClose: true,
                                    position: 'bottom-right',
                                }
                            );
                        }

                    }
                } else {
                    this.moshaToast(
                        {
                            title: `Stato non riconosciuto`,
                            description: `Stato Response: ${response.status}`,
                        },
                        {
                            showIcon: true,
                            hideProgressBar: false,
                            timeout: 6000,
                            type: 'info',
                            transition: 'bounce',
                            showCloseButton: true,
                            swipeClose: true,
                            position: 'bottom-right',
                        }
                    );
                }
            } catch (error) {
                this.loading = false;
                const descrizioneErrore = error.response?.data?.message ? error.response.data.message : error.message;
                this.moshaToast(
                    {
                        title: `Errore Generale ${error.status}`,
                        description: `Dettaglio: ${descrizioneErrore}.`
                    },
                    {
                        showIcon: true,
                        hideProgressBar: false,
                        timeout: 6000,
                        type: 'danger',
                        transition: 'bounce',
                        showCloseButton: true,
                        swipeClose: true,
                        position: 'bottom-right',
                    }
                );
            }
            this.loading = false;
        },
        goToVersionPage() {
            this.$router.push({ name: 'versione.index' });
        }
    },
    updated() {
        this.utente = this.store.getUtente;
    }, 
    mounted() {
        this.versione = this.store.getVersione;
        this.utente = this.store.getUtente;

        this.loadCitazione();
    }
}
</script>

<style scoped lang="scss">
@import '../../../assets/styles/variables';

/* X-Small devices (portrait phones, less than 576px)
No media query for `xs` since this is the default in Bootstrap */
.my_footer {
    display: flex;
    align-items: center;
    height: auto;
    background-color: $primary;
    color: #fff;
    margin-top: auto;
    padding: 18px;
}

.inside_container {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 20px;
}

.info {
    display: flex;
    justify-content: center;
    margin: 10px 0 20px 0;
}

.versione {
    display: flex;
    justify-content: center;
    margin: 10px 0 6px 0;
}

.cit {
    display: flex;
    justify-content: center;
    margin: 4px;
}

.cit_content {
    width: 100%;
    max-width: 400px;
    padding: 8px 40px 0 40px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 4px;
    background-color: #008cc245;
}


/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .inside_container {
        flex-direction: row;
        justify-content: space-between;
    }

    .info {
        display: flex;
        justify-content: flex-start;
        flex: 1;
    }

    .versione {
        display: flex;
        justify-content: flex-end;
        flex: 1;
    }

    .cit {
        flex: 2;
    }
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {}
</style>