import { defineStore } from 'pinia';

export const useDefaultStore = defineStore('storeDefault', {
    // arrow function recommended for full type inference
    state: () => {
        return {
            user: {
                authenticated: false,
                id: null,
                nome: null,
                cognome: null,
                nominativo: null,
                dashboard: false,
                permessi: [],
            },
            breadcrumb: [
                {
                    titolo: 'HOME',
                    fullPath: '/'
                }
            ],
            versione: process.env.VUE_APP_VERSIONE
        }
    },
    getters: {
        hasPermesso: (state) => (permesso) => {
            const arrayPermessi = Array.isArray(permesso) ? permesso : [permesso];
            return arrayPermessi.some(p => state.user.permessi.includes(p));
        },
        getPermessi(state) {
            return state.user.permessi;
        },
        hasDashboard(state) {
            return state.user.dashboard;
        },
        isAuthenticated(state) {
            return state.user.authenticated;
        },
        getBreadcrumb: (state) => {
            return state.breadcrumb;
        },
        getUtente: (state) => {
            return state.user;
        },
        getVersione: (state) => {
            return state.versione;
        },
        getAllState: (state) => {
            return state;
        }
    },
    actions: {
        resetUserStore() {
            this.user = {
                authenticated: false,
                id: null,
                nome: null,
                cognome: null,
                nominativo: null,
                dashboard: false,
                permessi: [],
            };
        },
        setAuthenticatedUser(user) {
            this.user = user;
        },
        addElementToBreadcrumb(element) {
            if (this.breadcrumb[0].fullPath != element.fullPath) {
                this.breadcrumb.unshift(element);
                this.breadcrumb = this.breadcrumb.slice(0, 7);
            }
        }
    },
    persist: {
        storage: localStorage,      // Puoi anche usare sessionStorage
        paths: ['user'],      // Solo lo stato 'permissions' sarà persistente
    }
})