const CreaCategoriaProdottoCantiere = () => import('@/pages/dashboard_globale/area/installazione/prodotto/categoria/CreaCategoriaProdotto.vue');
const ListaCategorieProdottiCantiere = () => import('@/pages/dashboard_globale/area/installazione/prodotto/categoria/ListaCategorieProdotti.vue');
const ModificaCategoriaProdottoCantiere = () => import('@/pages/dashboard_globale/area/installazione/prodotto/categoria/ModificaCategoriaProdotto.vue');

const routes = [
    {
        name: 'categorie.prodotti.cantiere.index',
        path: '/dashboard/categorie/prodotti/cantiere',
        component: ListaCategorieProdottiCantiere,
        meta: {
            titolo: 'Categoria Prodotti Cantiere',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'categoria.prodotto.cantiere.create',
        path: '/dashboard/categoria/prodotto/cantiere/create',
        component: CreaCategoriaProdottoCantiere,
        meta: {
            titolo: 'Crea Categoria Prodotto Cantiere',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'categoria.prodotto.cantiere.edit',
        path: '/dashboard/categoria/prodotto/cantiere/:categoriaId/edit',
        component: ModificaCategoriaProdottoCantiere,
        meta: {
            titolo: 'Modifica Categoria Prodotto Cantiere',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;