const ListaStato = () => import('@/pages/dashboard_globale/stati/sopralluogo/ListaStato.vue');
const CreaStato = () => import('@/pages/dashboard_globale/stati/sopralluogo/CreaStato.vue');
const ModificaStato = () => import('@/pages/dashboard_globale/stati/sopralluogo/ModificaStato.vue');

const ListaGruppiStato = () => import('@/pages/dashboard_globale/stati/sopralluogo/gruppo/ListaGruppiStato.vue');
const CreaGruppoStato = () => import('@/pages/dashboard_globale/stati/sopralluogo/gruppo/CreaGruppoStati.vue');
const ModificaGruppoStato = () => import('@/pages/dashboard_globale/stati/sopralluogo/gruppo/ModificaGruppoStati.vue');

const routes = [
    {
        name: 'gruppi.stato.sopralluogo.index',
        path: '/dashboard/gruppi/stato/sopralluogo',
        component: ListaGruppiStato,
        meta: {
            titolo: 'Gruppi Stato Sopralluogo',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'gruppo.stati.sopralluogo.create',
        path: '/dashboard/gruppo/stati/sopralluogo/create',
        component: CreaGruppoStato,
        meta: {
            titolo: 'Crea Gruppo per Stato Sopralluogo',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'gruppo.stati.sopralluogo.edit',
        path: '/dashboard/gruppo/stati/sopralluogo/:id/edit',
        component: ModificaGruppoStato,
        meta: {
            titolo: 'Modifica Gruppo Stato Sopralluogo',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'stati.sopralluogo.index',
        path: '/dashboard/stato/sopralluogo',
        component: ListaStato,
        meta: {
            titolo: 'Stato Sopralluogo',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'stato.sopralluogo.create',
        path: '/dashboard/stato/sopralluogo/create',
        component: CreaStato,
        meta: {
            titolo: 'Crea Stato Sopralluogo',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'stato.sopralluogo.edit',
        path: '/dashboard/stato/sopralluogo/:id/edit',
        component: ModificaStato,
        meta: {
            titolo: 'Modifica Stato Sopralluogo',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;