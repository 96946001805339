const ListaStatoInstallazione = () => import('@/pages/dashboard_globale/stati/installazione/ListaStatoInstallazione.vue');
const CreaStatoInstallazione = () => import('@/pages/dashboard_globale/stati/installazione/CreaStatoInstallazione.vue');
const ModificaStatoInstallazione = () => import('@/pages/dashboard_globale/stati/installazione/ModificaStatoInstallazione.vue');

const ListaGruppiStatoInstallazione = () => import('@/pages/dashboard_globale/stati/installazione/gruppo/ListaGruppiStatoInstallazione.vue');
const CreaGruppoStatoInstallazione = () => import('@/pages/dashboard_globale/stati/installazione/gruppo/CreaGruppoStatiInstallazione.vue');
const ModificaGruppoStatoInstallazione = () => import('@/pages/dashboard_globale/stati/installazione/gruppo/ModificaGruppoStatiInstallazione.vue');

const routes = [
    {
        name: 'gruppi.stato.installazione.index',
        path: '/dashboard/gruppi/stato/installazione',
        component: ListaGruppiStatoInstallazione,
        meta: {
            titolo: 'Gruppi Stato Installazione',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'gruppo.stati.installazione.create',
        path: '/dashboard/gruppo/stati/installazione/create',
        component: CreaGruppoStatoInstallazione,
        meta: {
            titolo: 'Crea Gruppo per Stato Installazione',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'gruppo.stati.installazione.edit',
        path: '/dashboard/gruppo/stati/installazione/:id/edit',
        component: ModificaGruppoStatoInstallazione,
        meta: {
            titolo: 'Modifica Gruppo Stato Installazione',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'stati.installazione.index',
        path: '/dashboard/stato/installazione',
        component: ListaStatoInstallazione,
        meta: {
            titolo: 'Stato Installazione',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'stato.installazione.create',
        path: '/dashboard/stato/installazione/create',
        component: CreaStatoInstallazione,
        meta: {
            titolo: 'Crea Stato Installazione',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'stato.installazione.edit',
        path: '/dashboard/stato/installazione/:id/edit',
        component: ModificaStatoInstallazione,
        meta: {
            titolo: 'Modifica Stato Installazione',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;