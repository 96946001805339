const CreaProdottoCantiere = () => import('@/pages/dashboard_globale/area/installazione/prodotto/CreaProdotto.vue');
const ListaProdottiCantiere = () => import('@/pages/dashboard_globale/area/installazione/prodotto/ListaProdotto.vue');
const ModificaProdottoCantiere = () => import('@/pages/dashboard_globale/area/installazione/prodotto/ModificaProdotto.vue');


const routes = [
    {
        name: 'prodotti.cantiere.index',
        path: '/dashboard/prodotti/cantiere',
        component: ListaProdottiCantiere,
        meta: {
            titolo: 'Prodotti Cantiere',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'prodotto.cantiere.create',
        path: '/dashboard/prodotto/cantiere/create',
        component: CreaProdottoCantiere,
        meta: {
            titolo: 'Crea Prodotto Cantiere',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'prodotto.cantiere.edit',
        path: '/dashboard/prodotto/cantiere/:prodottoId/edit',
        component: ModificaProdottoCantiere,
        meta: {
            titolo: 'Modifica Prodotto Cantiere',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;