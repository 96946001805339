const ListaSopralluogo = () => import('@/pages/core/sopralluogo/ListaSopralluogo');
const CreaSopralluogo = () => import('@/pages/core/sopralluogo/CreaSopralluogo');
const ModificaSopralluogo = () => import('@/pages/core/sopralluogo/ModificaSopralluogo');

const routes = [
    { 
        name: 'sopralluogo.create',
        path: '/sopralluogo/create/:clienteId?', 
        component: CreaSopralluogo,
        meta: {
            titolo: 'Crea Sopralluogo',
            requiresAuthentication: true,
            dashboard: false,
            permesso: 'sopralluogo.create'
        }
    },
    { 
        name: 'sopralluogo.index',
        path: '/sopralluogo', 
        component: ListaSopralluogo,
        meta: {
            titolo: 'Lista Sopralluogo',
            requiresAuthentication: true,
            dashboard: false,
            permesso: ['sopralluogo.index', 'sopralluogo.utente.index']
        }
    },
    { 
        name: 'sopralluogo.edit',
        path: '/sopralluogo/:sopralluogoId/edit', 
        component: ModificaSopralluogo,
        meta: {
            titolo: 'Modifica Sopralluogo',
            requiresAuthentication: true,
            dashboard: false,
            permesso: 'sopralluogo.show'
        }
    },

]

export default routes;