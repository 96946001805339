const ListaColonna = () => import('@/pages/dashboard_globale/manutenzione/viste/colonne/ListaColonna.vue');
const CreaColonna = () => import('@/pages/dashboard_globale/manutenzione/viste/colonne/CreaColonna.vue');
const ModificaColonna = () => import('@/pages/dashboard_globale/manutenzione/viste/colonne/ModificaColonna.vue');

const routes = [
    {
        name: 'colonna.index',
        path: '/dashboard/colonna',
        component: ListaColonna,
        meta: {
            titolo: 'Colonne',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'colonna.create',
        path: '/dashboard/colonna/create',
        component: CreaColonna,
        meta: {
            titolo: 'Crea Colonna',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'colonna.edit',
        path: '/dashboard/colonna/:id/edit',
        component: ModificaColonna,
        meta: {
            titolo: 'Modifica Colonna',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;