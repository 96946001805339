const ListaStatoTicket = () => import('@/pages/dashboard_globale/area/helpdesk/stato/ListaStato.vue');
const CreaStatoTicket = () => import('@/pages/dashboard_globale/area/helpdesk/stato/CreaStato.vue');
const ModificaStatoTicket = () => import('@/pages/dashboard_globale/area/helpdesk/stato/ModificaStato.vue');

const routes = [
    {
        name: 'stato.ticket.index',
        path: '/dashboard/stato/ticket',
        component: ListaStatoTicket,
        meta: {
            titolo: 'Stato Ticket',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'stato.ticket.create',
        path: '/dashboard/stato/ticket/create',
        component: CreaStatoTicket,
        meta: {
            titolo: 'Crea Stato Ticket',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'stato.ticket.edit',
        path: '/dashboard/stato/ticket/:statoId/edit',
        component: ModificaStatoTicket,
        meta: {
            titolo: 'Modifica Gruppo Stati Ticket',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;