const dataTimeForDB = data => {
    let result = null;
    if(data) {
        const toFormat = new Date(data);
    
        const yyyy = toFormat.getFullYear();
        let mm = (toFormat.getMonth() + 1).toString().padStart(2, '0');     // Aggiunge uno zero iniziale se necessario
        let dd = toFormat.getDate().toString().padStart(2, '0');            // Aggiunge uno zero iniziale se necessario
        
        let hh = toFormat.getHours().toString().padStart(2, '0');
        let min = toFormat.getMinutes().toString().padStart(2, '0');
    
        const formatted = `${yyyy}-${mm}-${dd} ${hh}:${min}`;
        result = formatted;
    }
    return result;
};

export default dataTimeForDB;