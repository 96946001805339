const longData = (data) => {
    if (data) {
        const toFormat = new Date(data);
        const yyyy = toFormat.getFullYear();
        let mm = toFormat.getMonth() + 1; // Months start at 0!
        let dd = toFormat.getDate();

        if (dd < 10) dd = '0' + dd;
        let mese = '';
        switch (mm) {
            case 1:
                mese = 'Gennaio';
                break;
            case 2:
                mese = 'Febbraio';
                break;
            case 3:
                mese = 'Marzo';
                break;
            case 4:
                mese = 'Aprile';
                break;
            case 5:
                mese = 'Maggio';
                break;
            case 6:
                mese = 'Giugno';
                break;
            case 7:
                mese = 'Luglio';
                break;
            case 8:
                mese = 'Agosto';
                break;
            case 9:
                mese = 'Settembre';
                break;
            case 10:
                mese = 'Ottobre';
                break;
            case 11:
                mese = 'Novembre';
                break;
            case 12:
                mese = 'Dicembre';
                break;

            default:
                break;
        }

        const formatted = dd + '/' + mese + '/' + yyyy;
        return formatted;
    } else {
        return null;
    }
};

export default longData;