const ListaTipologiaCliente = () => import('@/pages/dashboard_globale/area/sopralluogo/tipologiaCliente/ListaTipologiaCliente.vue');
const CreaTipologiaCliente = () => import('@/pages/dashboard_globale/area/sopralluogo/tipologiaCliente/CreaTipologiaCliente.vue');
const ModificaTipologiaCliente = () => import('@/pages/dashboard_globale/area/sopralluogo/tipologiaCliente/ModificaTipologiaCliente.vue');

const routes = [
    {
        name: 'tipologia.cliente.index',
        path: '/dashboard/tipologia/cliente',
        component: ListaTipologiaCliente,
        meta: {
            titolo: 'Tipologia Cliente',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'tipologia.cliente.create',
        path: '/dashboard/tipologia/cliente/create',
        component: CreaTipologiaCliente,
        meta: {
            titolo: 'Crea Tipologia Cliente',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'tipologia.cliente.edit',
        path: '/dashboard/tipologia/cliente/:id/edit',
        component: ModificaTipologiaCliente,
        meta: {
            titolo: 'Modifica Tipologia Cliente',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;