const VersionePage = () => import('@/pages/dashboard_globale/versione/VersionePage');

const routes = [
    {
        name: 'versione.index',
        path: '/dashboard/versione',
        component: VersionePage,
        meta: {
            titolo: 'Versione',
            requiresAuthentication: true,
            dashboard: true
        }
    },
];

export default routes;