const ListaTipologiaTicket = () => import('@/pages/dashboard_globale/area/helpdesk/tipologia/ListaTipologia.vue');
const CreaTipologiaTicket = () => import('@/pages/dashboard_globale/area/helpdesk/tipologia/CreaTipologia.vue');
const ModificaTipologiaTicket = () => import('@/pages/dashboard_globale/area/helpdesk/tipologia/ModificaTipologia.vue');

const routes = [
    {
        name: 'tipologia.ticket.index',
        path: '/dashboard/tipologia/ticket',
        component: ListaTipologiaTicket,
        meta: {
            titolo: 'Tipologia Ticket',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'tipologia.ticket.create',
        path: '/dashboard/tipologia/ticket/create',
        component: CreaTipologiaTicket,
        meta: {
            titolo: 'Crea Tipologia Ticket',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'tipologia.ticket.edit',
        path: '/dashboard/tipologia/ticket/:tipologiaId/edit',
        component: ModificaTipologiaTicket,
        meta: {
            titolo: 'Modifica Tipologia Ticket',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;