import moment from "moment";

const generaFilename = (prefix) => {
    const correction = {
        '/': "_",
        '\\': "_",
        ':': "_",
        '*': "_",
        '?': "_",
        '"': "_"
    };

    let filename = null;
    Object.keys(correction).forEach((key) => {
        filename = prefix.replaceAll(key, correction[key]);
    });
    filename = filename.toLowerCase();
    filename = filename.replaceAll(' ', '_');

    const currentTimestamp = moment(new Date()).format(
        "YYMMDDHHmm"
    );

    filename += '_' + currentTimestamp + '_' + generateRandomString(6);

    return filename;
};

const generateRandomString = (stringLength) => {
    let result = "";
    const alphaNumericCharacters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const alphabetsLength = alphaNumericCharacters.length;
    for (let i = 0; i < stringLength; i++) {
        result += alphaNumericCharacters.charAt(Math.floor(Math.random() * alphabetsLength));
    }
    return result;
};

export default generaFilename;