const CreaCalendario = () => import('@/pages/utente/calendario/CreaCalendario.vue');
const ModificaCalendario = () => import('@/pages/utente/calendario/ModificaCalendario.vue');

const routes = [
    { 
        name: 'calendario.create',
        path: '/calendario/create', 
        component: CreaCalendario,
        meta: {
            titolo: 'Crea Calendario',
            requiresAuthentication: true,
            dashboard: false
        }
    },
    { 
        name: 'calendario.edit',
        path: '/calendario/:calendarioId/edit', 
        component: ModificaCalendario,
        meta: {
            titolo: 'Modifica Calendario',
            requiresAuthentication: true,
            dashboard: false
        }
    },
]

export default routes;