const ListaGruppiStatoCliente = () => import('@/pages/dashboard_globale/stati/cliente/gruppo/ListaGruppiStato.vue');
const CreaGruppoStatoCliente = () => import('@/pages/dashboard_globale/stati/cliente/gruppo/CreaGruppoStati.vue');
const ModificaGruppoStatoCliente = () => import('@/pages/dashboard_globale/stati/cliente/gruppo/ModificaGruppoStati.vue');

const routes = [
    {
        name: 'gruppi.stato.cliente.index',
        path: '/dashboard/gruppi/stato/cliente',
        component: ListaGruppiStatoCliente,
        meta: {
            titolo: 'Gruppi Stato Cliente',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'gruppo.stati.cliente.create',
        path: '/dashboard/gruppo/stati/cliente/create',
        component: CreaGruppoStatoCliente,
        meta: {
            titolo: 'Crea Gruppo per Stato Cliente',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'gruppo.stati.cliente.edit',
        path: '/dashboard/gruppo/stati/cliente/:id/edit',
        component: ModificaGruppoStatoCliente,
        meta: {
            titolo: 'Modifica Gruppo Stato Cliente',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;