const ListaGruppo = () => import('@/pages/dashboard_globale/generale/gruppoUtente/ListaGruppo.vue');
const CreaGruppo = () => import('@/pages/dashboard_globale/generale/gruppoUtente/CreaGruppo.vue');
const ModificaGruppo = () => import('@/pages/dashboard_globale/generale/gruppoUtente/ModificaGruppo.vue');

const routes = [
    {
        name: 'gruppo.index',
        path: '/dashboard/gruppo',
        component: ListaGruppo,
        meta: {
            titolo: 'Gruppo',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'gruppo.create',
        path: '/dashboard/gruppo/create',
        component: CreaGruppo,
        meta: {
            titolo: 'Crea Gruppo',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'gruppo.edit',
        path: '/dashboard/gruppo/:gruppoId/edit',
        component: ModificaGruppo,
        meta: {
            titolo: 'Modifica Gruppo',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;