const ListaPermesso = () => import('@/pages/dashboard_globale/sicurezza/permesso/ListaPermesso.vue');
const CreaPermesso = () => import('@/pages/dashboard_globale/sicurezza/permesso/CreaPermesso.vue');
const ModificaPermesso = () => import('@/pages/dashboard_globale/sicurezza/permesso/ModificaPermesso.vue');

const routes = [
    {
        name: 'permesso.index',
        path: '/dashboard/permesso',
        component: ListaPermesso,
        meta: {
            titolo: 'Permesso',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'permesso.create',
        path: '/dashboard/permesso/create',
        component: CreaPermesso,
        meta: {
            titolo: 'Crea Permesso',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'permesso.edit',
        path: '/dashboard/permesso/:id/edit',
        component: ModificaPermesso,
        meta: {
            titolo: 'Modifica Permesso',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;