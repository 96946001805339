const ListaCalendario = () => import('@/pages/dashboard_globale/servizi/calendario/ListaCalendario.vue');

const ListaTipologiaEvento = () => import('@/pages/dashboard_globale/servizi/calendario/tipologiaEvento/ListaTipologiaEvento.vue');
const CreaTipologiaEvento = () => import('@/pages/dashboard_globale/servizi/calendario/tipologiaEvento/CreaTipologiaEvento.vue');
const ModificaTipologiaEvento = () => import('@/pages/dashboard_globale/servizi/calendario/tipologiaEvento/ModificaTipologiaEvento.vue');

const routes = [
    {
        name: 'calendario.index',
        path: '/dashboard/calendario',
        component: ListaCalendario,
        meta: {
            titolo: 'Lista Calendari',
            requiresAuthentication: true,
            dashboard: true
        }
    },

    {
        name: 'tipologia.evento.index',
        path: '/dashboard/tipologia/evento',
        component: ListaTipologiaEvento,
        meta: {
            titolo: 'Lista Tipologia Evento',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'tipologia.evento.create',
        path: '/dashboard/tipologia/evento/create',
        component: CreaTipologiaEvento,
        meta: {
            titolo: 'Crea Tipologia Evento',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'tipologia.evento.edit',
        path: '/dashboard/tipologia/evento/:id/edit',
        component: ModificaTipologiaEvento,
        meta: {
            titolo: 'Modifica Tipologia Evento',
            requiresAuthentication: true,
            dashboard: true
        }
    },
];

export default routes;