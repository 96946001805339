const ListaTicket = () => import('@/pages/dashboard_globale/servizi/helpdesk/ListaTicket.vue');
const ModificaTicket = () => import('@/pages/dashboard_globale/servizi/helpdesk/ModificaTicket.vue');

const routes = [
    { 
        name: 'helpdesk.index',
        path: '/dashboard/helpdesk', 
        component: ListaTicket,
        meta: {
            titolo: 'Lista Ticket',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    { 
        name: 'ticket.edit',
        path: '/ticket/:ticketId/edit', 
        component: ModificaTicket,
        meta: {
            titolo: 'Gestione Ticket',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;