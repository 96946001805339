const CreaProdottoCommerciale = () => import('@/pages/dashboard_globale/area/amministrazione/prodotto/CreaProdotto.vue');
const ListaProdottiCommerciale = () => import('@/pages/dashboard_globale/area/amministrazione/prodotto/ListaProdotto.vue');
const ModificaProdottoCommerciale = () => import('@/pages/dashboard_globale/area/amministrazione/prodotto/ModificaProdotto.vue');

const routes = [
    {
        name: 'prodotti.commerciale.index',
        path: '/dashboard/prodotti/commerciale',
        component: ListaProdottiCommerciale,
        meta: {
            titolo: 'Prodotti Commerciale',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'prodotto.commerciale.create',
        path: '/dashboard/prodotto/commerciale/create',
        component: CreaProdottoCommerciale,
        meta: {
            titolo: 'Crea Prodotto Commerciale',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'prodotto.commerciale.edit',
        path: '/dashboard/prodotto/commerciale/:prodottoId/edit',
        component: ModificaProdottoCommerciale,
        meta: {
            titolo: 'Modifica Prodotto Commerciale',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;