const ListaTabellaStorico = () => import('@/pages/dashboard_globale/storico/tabella/ListaTabellaStorico.vue');
const CreaTabellaStorico = () => import('@/pages/dashboard_globale/storico/tabella/CreaTabellaStorico.vue');
const ModificaTabellaStorico = () => import('@/pages/dashboard_globale/storico/tabella/ModificaTabellaStorico.vue');

const routes = [
    {
        name: 'tabella.storico.index',
        path: '/dashboard/tabella/storico',
        component: ListaTabellaStorico,
        meta: {
            titolo: 'Tabella Storico',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'tabella.storico.create',
        path: '/dashboard/tabella/storico/create',
        component: CreaTabellaStorico,
        meta: {
            titolo: 'Crea Tabella Storico',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'tabella.storico.edit',
        path: '/dashboard/tabella/storico/:id/edit',
        component: ModificaTabellaStorico,
        meta: {
            titolo: 'Modifica Tabella Storico',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;