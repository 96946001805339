const ListaTicketUtente = () => import('@/pages/helpdesk/ListaTicketUtente.vue');
const CreaTicket = () => import('@/pages/helpdesk/CreaTicket.vue');
const ModificaTicket = () => import('@/pages/helpdesk/ModificaTicket.vue');

const routes = [
    { 
        name: 'helpdesk.index.utente',
        path: '/helpdesk', 
        component: ListaTicketUtente,
        meta: {
            titolo: 'I Miei Ticket',
            requiresAuthentication: true,
            dashboard: false
        }
    },
    { 
        name: 'ticket.create.utente',
        path: '/ticket/create/utente', 
        component: CreaTicket,
        meta: {
            titolo: 'Nuovo Ticket',
            requiresAuthentication: true,
            dashboard: false
        }
    },
    { 
        name: 'ticket.edit.utente',
        path: '/ticket/:ticketId/edit/utente', 
        component: ModificaTicket,
        meta: {
            titolo: 'Gestione Ticket',
            requiresAuthentication: true,
            dashboard: false
        }
    },
]

export default routes;