const getIcon = (ext) => {
    if (!ext) {
        return null;
    }

    let icona = null;
    switch (ext) {
        case "pdf":
        case ".pdf":
            icona = 'bi-file-earmark-pdf';
            break;

        case "jpeg":
        case ".jpeg":
        case "jpg":
        case ".jpg":
        case "png":
        case ".png":
            icona = 'bi-file-earmark-image';
            break;

        case "zip":
        case ".zip":
            icona = 'bi-file-earmark-zip';
            break;

        default:
            icona = 'bi-file-earmark';
            break;
    }

    return icona;
};

export default getIcon;