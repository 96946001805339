const ArchivioModulo = () => import('@/pages/modulo/ArchivioModulo.vue');

const routes = [
    { 
        name: 'modulo.archivio',
        path: '/modulo/archivio', 
        component: ArchivioModulo,
        meta: {
            titolo: 'Archivio Moduli',
            requiresAuthentication: true,
            dashboard: false
        }
    },
]

export default routes;