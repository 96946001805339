
const TheHome = () => import('@/pages/TheHome');
const WorkInProgress = () => import('@/pages/WorkInProgress');

const ListaFaq = () => import('@/pages/faq/ListaFaq.vue');

const NotFound = () => import('@/pages/NotFound');

const routes = [
    { 
        name: 'home',
        path: '/', 
        component: TheHome,
        meta: {
            titolo: 'Home',
            requiresAuthentication: false,
            dashboard: false,
        }
    },
    { 
        name: 'faq.index',
        path: '/faq', 
        component: ListaFaq,
        meta: {
            titolo: 'Frequent Asked Questions',
            requiresAuthentication: true,
            dashboard: false
        }
    },

    { 
        name: 'work.in.progress',
        path: '/work/in/progress', 
        component: WorkInProgress,
        meta: {
            titolo: 'Work In Progress',
            requiresAuthentication: true,
            dashboard: false
        }
    },
    
    { 
        path: '/:notFound(.*)', 
        component: NotFound
    }
]

export default routes;