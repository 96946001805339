const CentroNotifiche = () => import('@/pages/notifiche/CentroNotifiche.vue');
const UnsubscribeMail = () => import('@/pages/notifiche/UnsubscribeMail.vue');

const routes = [
    { 
        name: 'notifica.index',
        path: '/centro/notifica', 
        component: CentroNotifiche,
        meta: {
            titolo: 'Centro Notifiche',
            requiresAuthentication: true,
            dashboard: false
        }
    },
    { 
        name: 'unsubscribe.mail',
        path: '/unsubscribe/mail', 
        component: UnsubscribeMail,
        meta: {
            titolo: 'Disattiva Mail Automatiche',
            requiresAuthentication: true,
            dashboard: false
        }
    },
]

export default routes;