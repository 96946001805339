const calcolaData = (data, giorni) => {
    const toFormat = new Date(data);

    // Aggiunta dei giorni all'oggetto Date
    toFormat.setDate(toFormat.getDate() + giorni);

    /* const yyyy = toFormat.getFullYear();
    let mm = (toFormat.getMonth() + 1).toString().padStart(2, '0');     // Aggiunge uno zero iniziale se necessario
    let dd = toFormat.getDate().toString().padStart(2, '0');            // Aggiunge uno zero iniziale se necessario

    const formatted = dd + '/' + mm + '/' + yyyy; */
    
    return toFormat;
};

export default calcolaData;