const ListaOrdini = () => import('@/pages/core/ordine/ListaOrdini.vue');
const ModificaOrdine = () => import('@/pages/core/ordine/ModificaOrdine.vue');
const CreaOrdine = () => import('@/pages/core/ordine/CreaOrdine.vue');
const PanoramicaCantiere = () => import('@/pages/core/ordine/PanoramicaCantiere.vue');

const routes = [
    { 
        name: 'panoramica.cantieri',
        path: '/panoramica/cantieri', 
        component: PanoramicaCantiere,
        meta: {
            titolo: 'Panoramica Cantieri',
            requiresAuthentication: true,
            dashboard: false,
            permesso: ['panoramica.cantieri']
        }
    },
    { 
        name: 'ordine.index',
        path: '/ordine', 
        component: ListaOrdini,
        meta: {
            titolo: 'Lista Ordini',
            requiresAuthentication: true,
            dashboard: false,
            permesso: ['ordine.index', 'ordine.utente.index']
        }
    },
    { 
        name: 'ordine.edit',
        path: '/ordine/:ordineId/edit', 
        component: ModificaOrdine,
        meta: {
            titolo: 'Modifica Ordine',
            requiresAuthentication: true,
            dashboard: false,
            permesso: 'ordine.show'
        }
    },
    { 
        name: 'ordine.create',
        path: '/ordine/create/:clienteId?', 
        component: CreaOrdine,
        meta: {
            titolo: 'Crea Ordine',
            requiresAuthentication: true,
            dashboard: false,
            permesso: 'ordine.create'
        }
    },
]

export default routes;