const ListaRuolo = () => import('@/pages/dashboard_globale/sicurezza/ruolo/ListaRuolo.vue');
const CreaRuolo = () => import('@/pages/dashboard_globale/sicurezza/ruolo/CreaRuolo.vue');
const ModificaRuolo = () => import('@/pages/dashboard_globale/sicurezza/ruolo/ModificaRuolo.vue');

const routes = [
    {
        name: 'ruolo.index',
        path: '/dashboard/ruolo',
        component: ListaRuolo,
        meta: {
            titolo: 'Ruolo',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'ruolo.create',
        path: '/dashboard/ruolo/create',
        component: CreaRuolo,
        meta: {
            titolo: 'Crea Ruolo',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'ruolo.edit',
        path: '/dashboard/ruolo/:id/edit',
        component: ModificaRuolo,
        meta: {
            titolo: 'Modifica Ruolo',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;