const ListaStatiOrdine = () => import('@/pages/dashboard_globale/stati/ordine/stati/ListaStati.vue');
const CreaStatoOrdine = () => import('@/pages/dashboard_globale/stati/ordine/stati/CreaStato.vue');
const ModificaStatoOrdine = () => import('@/pages/dashboard_globale/stati/ordine/stati/ModificaStato.vue');

const routes = [
    {
        name: 'stati.ordine.index',
        path: '/dashboard/stati/ordine',
        component: ListaStatiOrdine,
        meta: {
            titolo: 'Stati PDA',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'stato.ordine.create',
        path: '/dashboard/stato/ordine/create',
        component: CreaStatoOrdine,
        meta: {
            titolo: 'Crea Stato PDA',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'stato.ordine.edit',
        path: '/dashboard/stato/ordine/:id/edit',
        component: ModificaStatoOrdine,
        meta: {
            titolo: 'Modifica Stato PDA',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;