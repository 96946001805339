const ListaDocumentazione = () => import('@/pages/documentazione/ListaDocumentazione.vue');

const routes = [
    { 
        name: 'documentazione.index',
        path: '/documentazione', 
        component: ListaDocumentazione,
        meta: {
            titolo: 'Documentazione',
            requiresAuthentication: true,
            dashboard: false,
            permesso: 'articolo.index'
        }
    },
]

export default routes;