const ListaCategoriaDocumenti = () => import('@/pages/dashboard_globale/manutenzione/documenti/categoria/ListaCategoria.vue');
const CreaCategoriaDocumenti = () => import('@/pages/dashboard_globale/manutenzione/documenti/categoria/CreaCategoria.vue');
const ModificaCategoriaDocumenti = () => import('@/pages/dashboard_globale/manutenzione/documenti/categoria/ModificaCategoria.vue');

const routes = [
    {
        name: 'categoria.documenti.index',
        path: '/dashboard/categoria/documenti',
        component: ListaCategoriaDocumenti,
        meta: {
            titolo: 'Categorie Documenti',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'categoria.documenti.create',
        path: '/dashboard/categoria/documenti/create',
        component: CreaCategoriaDocumenti,
        meta: {
            titolo: 'Crea Categoria Documenti',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'categoria.documenti.edit',
        path: '/dashboard/categoria/documenti/:categoriaId/edit',
        component: ModificaCategoriaDocumenti,
        meta: {
            titolo: 'Modifica Categoria Documenti',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;