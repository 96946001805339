const ListaAgenzie = () => import('@/pages/dashboard_globale/generale/agenzia/ListaAgenzie.vue');
const CreaAgenzia = () => import('@/pages/dashboard_globale/generale/agenzia/CreaAgenzia.vue');
const ModificaAgenzia = () => import('@/pages/dashboard_globale/generale/agenzia/ModificaAgenzia.vue');

const routes = [
    {
        name: 'agenzia.index',
        path: '/dashboard/agenzia',
        component: ListaAgenzie,
        meta: {
            titolo: 'Agenzie',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'agenzia.create',
        path: '/dashboard/agenzia/create',
        component: CreaAgenzia,
        meta: {
            titolo: 'Crea Agenzia',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'agenzia.edit',
        path: '/dashboard/agenzia/:agenziaId/edit',
        component: ModificaAgenzia,
        meta: {
            titolo: 'Modifica Agenzia',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;