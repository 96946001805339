const FunzioniAutenticazione = () => import('@/pages/dashboard_globale/migrazione/autenticazione/FunzioniAutenticazione.vue');
const FunzioniUtente = () => import('@/pages/dashboard_globale/migrazione/utente/FunzioniUtente.vue');
const FunzioniDati = () => import('@/pages/dashboard_globale/migrazione/dati/FunzioniDati.vue');

const routes = [
    {
        name: 'funzioni.dati',
        path: '/dashboard/funzioni/dati',
        component: FunzioniDati,
        meta: {
            titolo: 'Funzioni Dati',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'funzioni.autenticazione',
        path: '/dashboard/funzioni/autenticazione',
        component: FunzioniAutenticazione,
        meta: {
            titolo: 'Funzioni Autenticazione',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'funzioni.utente',
        path: '/dashboard/funzioni/utente',
        component: FunzioniUtente,
        meta: {
            titolo: 'Funzioni Utenti',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;