const GestioneMagazzino = () => import('@/pages/magazzino/GestioneMagazzino');

const routes = [
    { 
        name: 'magazzino.index',
        path: '/magazzino', 
        component: GestioneMagazzino,
        meta: {
            titolo: 'Gestione Magazzino',
            requiresAuthentication: true,
            dashboard: false
        }
    },
]

export default routes;