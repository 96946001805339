const ListaFinanziarie = () => import('@/pages/dashboard_globale/area/amministrazione/finanziaria/ListaFinanziarie.vue');
const CreaFinanziaria = () => import('@/pages/dashboard_globale/area/amministrazione/finanziaria/CreaFinanziaria.vue');
const ModificaFinanziaria = () => import('@/pages/dashboard_globale/area/amministrazione/finanziaria/ModificaFinanziaria.vue');

const routes = [
    {
        name: 'finanziaria.index',
        path: '/dashboard/finanziarie',
        component: ListaFinanziarie,
        meta: {
            titolo: 'Finanziarie',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'finanziaria.create',
        path: '/dashboard/finanziaria/create',
        component: CreaFinanziaria,
        meta: {
            titolo: 'Crea Finanziaria',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'finanziaria.edit',
        path: '/dashboard/finanziaria/:finanziariaId/edit',
        component: ModificaFinanziaria,
        meta: {
            titolo: 'Modifica Finanziaria',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;