const ListaGruppiStatoTicket = () => import('@/pages/dashboard_globale/area/helpdesk/stato/gruppo/ListaGruppiStato.vue');
const CreaGruppoStatoTicket = () => import('@/pages/dashboard_globale/area/helpdesk/stato/gruppo/CreaGruppoStati.vue');
const ModificaGruppoStatoTicket = () => import('@/pages/dashboard_globale/area/helpdesk/stato/gruppo/ModificaGruppoStati.vue');

const routes = [
    {
        name: 'gruppi.stato.ticket.index',
        path: '/dashboard/gruppi/stato/ticket',
        component: ListaGruppiStatoTicket,
        meta: {
            titolo: 'Gruppi Stato Ticket',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'gruppo.stati.ticket.create',
        path: '/dashboard/gruppo/stati/ticket/create',
        component: CreaGruppoStatoTicket,
        meta: {
            titolo: 'Crea Gruppo per Stati Ticket',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'gruppo.stati.ticket.edit',
        path: '/dashboard/gruppo/stati/ticket/:gruppoId/edit',
        component: ModificaGruppoStatoTicket,
        meta: {
            titolo: 'Modifica Gruppo Stati Ticket',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;